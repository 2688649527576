/*
Template Name: Corelaw- Law firm, Lawyers & Legal Service HTML Template
*/

/*================================================
1. Mixins Css
2. Global CSS
3. Section Title
4. Buttons
5. Preloader
6. Pagination
7. search area
8. Mobile-menu 
9. Topbar-section
10. Header-section
11. Banner-section
12. Intro-section
13. About-section
14. Practice-area-section
15. Info-section
16. Casestudy Section
17. Attorney Section
18. Latest News Section
19. Lawyer Profile Section
20. Error-page
21. Footer-section
22. Testimonial Seciton
23. Breadcrumb-section
24. Service details
25. Faq section
26. History section

================================================*/

// @import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
:root {
    /*color Variable*/
    --primary-color1: #DEA057;
    --primary-color2: #CA9457;
    --primary-color3: rgb(215, 182, 121);

    --primary-light2: #fbf8f6;
    --primary-light: rgba(222, 160, 87,0.15);
    --dark: #202020;
    --dark2: #1B1B1B;

    --dark3:#1B1B22;
    --dark-mode-bg:#27272E;
    --dark-mode-light: #D9D9D9;
    --dark-light: rgba(32, 32, 32,0.15);
    --text-light2:#B6B6B6;

    --green: #005153;

    --light-color: #FAFAFA;

    --text-color1: #292B31;
    --text-color2: #333333;
    --text-light: #9B9B9B;

    --light-bg: #F8F8F8;
    --dark-bg: #0A120F;
    --dark--bg: #0A120F;
    --white: #fff;

    --font-lora: 'Lora', serif;
    --font-exo: 'Exo', sans-serif;
  }

  ::selection {
    color: var(--white);
    background: var(--primary-color1);
    -webkit-text-fill-color: var(--white);

  }
  @font-face {
    font-family: 'Lora';
    src: url(./fonts/Lora-Bold.ttf) format('truetype');
    font-weight: 700;
  }
@font-face {
    font-family: 'Lora';
    src: url(./fonts/Lora-SemiBold.ttf) format('truetype');
    font-weight: 600;
  }
@font-face {
    font-family: 'Lora';
    src: url(./fonts/Lora-Medium.ttf) format('truetype');
    font-weight: 500;
  }
@font-face {
    font-family: 'Lora';
    src: url(./fonts/Lora-Regular.ttf) format('truetype');
    font-weight: 400;
  }
@font-face {
    font-family: 'Exo';
    src: url(./fonts/Exo-ExtraBold.ttf) format('truetype');
    font-weight: 800;
  }
@font-face {
    font-family: 'Exo';
    src: url(./fonts/Exo-Bold.ttf) format('truetype');
    font-weight: 700;
  }
@font-face {
    font-family: 'Exo';
    src: url(./fonts/Exo-SemiBold.ttf) format('truetype');
    font-weight: 600;
  }
@font-face {
    font-family: 'Exo';
    src: url(./fonts/Exo-Medium.ttf) format('truetype');
    font-weight: 500;
  }
@font-face {
    font-family: 'Exo';
    src: url(./fonts/Exo-Regular.ttf) format('truetype');
    font-weight: 400;
  }

 
@font-face {
    font-family: 'Exo';
    src: url(./fonts/Exo-Light.ttf) format('truetype');
    font-weight: 300;
  }

 
/*================================================
1. Mixins Css
=================================================*/

// flex-box
@mixin flex($position) {
    display: flex;
    justify-content: $position;
    // flex-wrap: wrap;
    align-items: center;
}
@mixin img-adjust{
    background-size: cover;
    background-repeat: no-repeat;
}
@mixin font($font-size, $font-weight, $color){
    font-size: $font-size;
    font-weight: $font-weight;
    color: $color;
}
@mixin list($style, $margin, $padding) {
    list-style: $style;
    margin : $margin;
    padding: $padding;
}
@mixin display($none, $hidden){
    display: $none;
    visibility: $hidden;
}
@mixin circle($width,$height,$line-height,$center,$bor-radius){
    width: $width;
    height: $height;
    line-height: $line-height;
    text-align: center;
    border-radius: 50%;
}

@mixin hover-style {
    position: relative;
    transition: 0.4s ease;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        background: rgba(var(--primary-color1), 0.5);
        height: 0px;
        transition: 0.5s ease-in-out;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 0px;
        background: rgba(var(--primary-color1), 0.5);
        transition: 0.5s ease-in-out;
        z-index: 1;
    }

    &:hover {
        border-top: 1px solid rgba(var(--primary-color1), 0.5);
        border-bottom: 1px solid rgba(var(--primary-color1), 0.5);
        &::before {
            height: 100%;
        }
        &::after {
            height: 100%;
        }
    }
}

// xl-device=====
@mixin fifteen-down-device {
    @media (max-width:1500px) {
        @content;
    }
}
@mixin xxl-down-device {
    @media (max-width:1399px) {
        @content;
    }
}
@mixin xl-down-device {
    @media (max-width:1199px) {
        @content;
    }
}
@mixin xxl-device {
    @media (min-width:1400px) and (max-width:1599px) {
        @content;
    }
}
@mixin xl-device {
    @media (min-width:1200px) and (max-width:1399px) {
        @content;
    }
}
@mixin lg-device {
    @media (min-width:992px) and (max-width:1199px) {
        @content;
    }
}
@mixin lg-up-device {
    @media (min-width:992px) {
        @content;
    }
}
@mixin lg-down-device {
    @media (max-width:991px) {
        @content;
    }
}
// md-device============
@mixin md-device {
    @media (min-width:768px) and (max-width:991px) {
        @content;
    }
}
@mixin md-up-device {
    @media (min-width:768px) {
        @content;
    }
}
@mixin md-down-device {
    @media (max-width:767px) {
        @content;
    }
}
// sm-device
@mixin sm-device {
    @media (min-width: 576px) and (max-width:768px) {
        @content;
    }
}
@mixin sm-down-device {
    @media (max-width:576px) {
        @content;
    }
}

  /*================================================
2. Global Css
=================================================*/
html{
    font-size: 1rem;
    @include xl-device(){
        font-size: 97%;
    }
    @include lg-device(){
        font-size: 94%;
    }
    @include md-device(){
        font-size: 90%;
    }
    @include sm-device(){
        font-size: 87%;
    }
    @include sm-down-device(){
        font-size: 84%;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-lora);
}

body {
    font-family: var(--font-exo);
}
button{
    outline: none;
    border: none;
}

a {
    color: unset;
    text-decoration: none;

    &:focus {
        outline: 0 solid;
    }

    &:hover {
        text-decoration: none;
        color: unset;
    }
}

input:focus,
textarea:focus,
button:focus {
    outline: none;
}

i.bx {
    vertical-align: middle;
}
.para{
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-exo);
    color: var(--text-color2);
    line-height: 1.8;
}
.dark-mode-bg{
    background-color: var(--dark-mode-bg);
}
.dark3-bg{
    background-color: var(--dark3);
}

//   padding
.pt-120 {
    padding-top: 120px;

    @include lg-device(){
        padding-top: 100px;
    }

    @include lg-down-device(){
        padding-top: 90px;
    }
}

.pb-120 {
    padding-bottom: 120px;

    @include lg-device(){
        padding-bottom: 100px;
    }

    @include lg-down-device(){
        padding-bottom: 90px;
    }
}
.pb-65{
    padding-bottom: 65px !important;
}
.mt-120 {
    margin-top: 120px;

    @include lg-device(){
        margin-top: 100px;
    }

    @include lg-down-device(){
        margin-top: 90px;
    }
}
.mb-120{
    margin-bottom: 120px;
    @include lg-device(){
        
        margin-bottom: 100px;
    }

    @include lg-down-device(){
        margin-bottom: 90px;
    }
}


.pt-80{
    padding-top: 80px;
}
.pb-80{
    padding-bottom: 80px;
}
.pb-60{
    padding-bottom: 60px;
}

.mb-60{
    margin-bottom: 60px;
}
.mb-50{
    margin-bottom: 50px;
}
.mb-45{
    margin-bottom: 45px;
}
.mt-10{
    margin-top: 10px;
}
.mt-40{
    margin-top: 40px;
}
.mb-40{
    margin-bottom: 40px;
}
.mb-30{
    margin-bottom: 30px;
}
.mt-50{
    margin-top: 50px;
}
.mb-50{
    margin-bottom: 50px;
}
.mt-60{
    margin-top: 60px;
}
.bg--light{
    background: #FAFAFA;
}

.para{  
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color2);
    font-family: var(--font-exo);
    line-height: 1.8;
}
.eg-card {
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}
// .slick-slide {
//     margin-right: 15px;
//     // margin-left: 15px;
//   }

    .slick-initialized .slick-slide{
        padding: 0 10px;
    }
    .law-cetagory {
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
        // backdrop-filter: blur(15px);
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-light);
        height: 52px;
        line-height: 52px;
        outline: none;
        position: relative;
        text-align: left !important;
        transition: all 0.2s ease-in-out;
        user-select: none;
        white-space: nowrap;
        width: 100%;
        border-radius: 5px;
        border: 1px solid rgba(202,148,87,.33);
        padding: 14px 20px;
        // border: 1px solid green;
        z-index: 1;
        margin-bottom: 40px;
        option{
            line-height: 40px;
            font-size: 14px;
            border: unset;
            border: 1px solid rgba(41, 43, 49, 0.03);
            margin-top: 3px;
            z-index: 100;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
            min-width: 80px;
            background: var(--dark);
            &:hover{
                background: var(--primary-color2);
                color: var(--white);
            }
            &.selected{
                background: var(--primary-color2);
            }
        }
   
        
    }
    .swiper-wrapper{
        padding: 0;
    }
    .law-cetagory-contact {
        background: #fff;
        // backdrop-filter: blur(15px);
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 16px;
        font-weight: 500;
        color: var(--text-light);
        height: 52px;
        line-height: 52px;
        outline: none;
        position: relative;
        text-align: left !important;
        transition: all 0.2s ease-in-out;
        user-select: none;
        white-space: nowrap;
        width: 100%;
        border-radius: 5px;
        border: 1px solid rgba(41, 43, 49, 0.05);
        padding: 14px 20px;
        // border: 1px solid green;
        z-index: 1;
        margin-bottom: 40px;
        option{
            line-height: 40px;
            font-size: 16px;
            border: unset;
            border: 1px solid rgba(41, 43, 49, 0.03);
            margin-top: 3px;
            z-index: 100;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
            min-width: 80px;
            color:#000;
            background: #fff;
            &:hover{
                background: var(--primary-color2);
                color: var(--white);
            }
            &.selected{
                background: var(--primary-color2);
            }
        }
   
        
    }
/*=======================================
 3. section-title
=======================================*/
.section-title1{
    margin-bottom: 60px;
    h2{
        font-size: 3rem;
        font-weight: 700;
        color: #DEA057;
        background: -webkit-linear-gradient(40deg, #202020 0%, #DEA054 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 15px;
        margin-top: -7px;
        display: inline-block;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: var(--text-color2);
        line-height: 1.8;
    }
}
.section-title2{
    span{
        font-size: 1rem;
        font-weight: 400;
        color: var(--primary-color2);
        position: relative;
        padding-bottom: 8px;
        margin-bottom: 15px;
        display: inline-block;
        &::after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 100px;
            height: 3px;
            background: linear-gradient(90deg, #005153 0%, rgba(0, 81, 83, 0.786458) 21.35%, rgba(0, 81, 83, 0.453125) 54.69%, rgba(0, 81, 83, 0.151042) 84.9%, rgba(0, 81, 83, 0) 100%);
            border-radius: 23px;
        }
    }
    h2{
        font-size: 2.6rem;
        font-weight: 700;
        color: var(--text-color);
        line-height: 1.3;
        margin-bottom: 0px;
        text-transform: capitalize;

        @include sm-down-device(){
            font-size: 2rem;
        }
    }
    &.sibling2{
        max-width: 600px;
        width: 100%;
        span{
            color: var(--primary-color2);
            position: relative;
            &::after{
                position: absolute;
                left: 0px;
                transform: translateX(0);
                background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.786458) 21.35%, rgba(255, 255, 255, 0.453125) 54.69%, rgba(255, 255, 255, 0.151042) 84.9%, rgba(255, 255, 255, 0) 100%);
            }
        }
        h2{
            color: var(--white);
        }
    }
    &.sibling3{
        span{

            color: var(--primary-color2);
            position: relative;
            &::after{
                position: absolute;
                left: 0px;
                transform: translateX(0);
                background: linear-gradient(90deg, #005153 0%, rgba(0, 81, 83, 0.786458) 21.35%, rgba(0, 81, 83, 0.453125) 54.69%, rgba(0, 81, 83, 0.151042) 84.9%, rgba(0, 81, 83, 0) 100%);
            }
            @include sm-down-device(){
                margin-left:unset
            }
        }
        h2{
            color: var(--text-color1);
        }
    }
}
.section-title-area{ 
    position: relative;
    z-index: 1;
    overflow: hidden;
    .section-title{
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 55px;
        z-index: 9;
        background-color: transparent;
        margin-top: -5px;
        span{
            @include font(14px, 500,var(--primary-color3));
            position: relative;
            display: inline-block;
            margin-bottom: 20px;
            &::before{
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 8px;
                height: 2px;
                width: 150%;
                background: linear-gradient(90deg, #D7B679 0%, rgba(215, 182, 121, 0) 17.57%, rgba(215, 182, 121, 0) 84.67%, #D7B679 95.32%);
            }
        }
        h2{
            @include font(2.625rem,700, var(--white));
            margin-bottom: 0px;
            line-height: 1.4;
            text-transform: capitalize;
            @include sm-device(){
                font-size: 2.2rem;
            }
            @include sm-down-device(){
                font-size: 2.1rem;
            }
        }
    }

    hr{
        border-bottom: 1px dashed rgba(#fff,0.45);
        height: 1px;
        padding-top: 30px;
        background: var(--dark3);
        @include xl-down-device(){
            display: none;
            visibility: hidden;
        }
    }
    &.sibling2{
        .section-title{
            h2{
                color: var(--dark3);
            }
        }
        marquee{
            position: absolute;
            z-index: -1;
            top: 0;
            @include font(5rem, 600, transparent);
            -webkit-text-stroke: 1px rgba( #1B1B22,0.08);
            -webkit-text-fill-color: transparent;
        }
    }
    &.sibling3{
        .section-title{
            margin-left: 0;
            margin-bottom: 25px;
            span{
                margin-left: 25px;
                margin-bottom: 15px;
            }
            @include xl-down-device(){
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &.sibling4{
        .section-title{
            margin-left: 0;
            margin-bottom: 25px;
            h2{
                color: var(--dark3);
            }
            span{
                margin-left: 25px;
                margin-bottom: 15px;
            }
            @include xl-down-device(){
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

}

.marquee {
    width: 100%;
  
    // overflow: hidden;
    position: relative;
  }
  
  .marquee div {
    display: block;
    width: 200%;
    height: 30px;
  
    position: absolute;
    // overflow: hidden;
  
    animation: marquee 20000ms linear infinite;
  }
  
  .marquee span {
    float: left;
    line-height: 1;
    width: 50%;
    @include font(5rem, 600, transparent);
    -webkit-text-stroke: 1px rgba(#999,0.18);
    -webkit-text-fill-color: transparent;
    animation: train 8s linear infinite;
  }
  
  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }

/*=======================================
 4. Buttons 
=======================================*/
.eg-btn {
    text-align: center;
    display: inline-block;
    text-decoration: none;
    transition: all 0.45s ease-in-out;
    text-transform: capitalize;
    font-family: var(--font-exo);
    cursor: pointer;
    overflow: hidden;
    i{
        font-weight: 200;
        vertical-align: middle;
        margin-right: 5px;
        
    }
}

.btn--primary {
    background: var(--primary-color1);
    color: var(--dark);
    border-radius: 0px;
    position: relative;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--dark);
        width: 100%;
        height: 100%;
        transition: 0.5s;
        z-index: -1;
        transform: scaleX(0);
        border-radius: 0px;
    }
    &:hover{
        color: var(--white);
        &::after{
            transform: scaleX(1); 
        }
    }
}
.btn--primary2 {
    background: var(--primary-color2);
    color: var(--white);
    border-radius: 0px;
    position: relative;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--dark);
        width: 100%;
        height: 100%;
        transition: 0.5s;
        z-index: -1;
        transform: scaleX(0);
        border-radius: 0px;
    }
    &:hover{
        color: var(--white);
        &::after{
            transform: scaleX(1); 
        }
    }
    &.sibling2{
        border-radius: 10px;
        line-height: 32px;
        height: 52px;
        background-color: var(--primary-color3);
        color: var(--text-color1);
        &:hover{
            color: var(--white);
        }
    }
}
.btn--dark {    
    background: var(--dark);
    color: var(--white);
    border-radius: 0px;
    position: relative;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--primary-color1);
        width: 100%;
        height: 100%;
        transition: 0.5s;
        z-index: -1;
        transform: scaleX(0);
        border-radius: 0px;
    }
    &:hover{
        &::after{
            transform: scaleX(1); 
        }
    }
}
.btn--outline {
    background: transparent;
    color: var(--dark);
    border: 1px solid var(--primary-color1);
    position: relative;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--dark);
        width: 100%;
        height: 100%;
        transition: 0.5s;
        z-index: -1;
        transform: scaleX(0);
        border-radius: 0px;
    }
    &:hover{
        transform: scaleX(1); 
    }
    &.style-2{
        border: 1px solid var(--primary-color1);
        &::after {
            background: var(--primary-color1);
        }
    }
    &.sibling3{
        color: var(--primary-color2);
        border: 1px solid #757575;;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: var(--dark);
            width: 100%;
            height: 100%;
            transition: 0.5s;
            z-index: -1;
            transform: scaleX(0);
            border-radius: 0px;
        }
        &:hover{
            &::after{
                transform: scaleX(1); 
            }
        }
        svg{
            fill: var(--primary-color2);
        }
    }
}
.capsule{
    border-radius: 30px;
}

.btn--lg{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 15px 35px;
    @include md-down-device(){
        padding: 12px 25px;
    }
}
.btn--lg2{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px 20px;
    @include md-down-device(){
        padding: 12px 25px;
    }
}
.btn--md{
    padding: 10px 35px;
    font-size: 15px;
    font-weight: 700;
    @include md-down-device(){
        padding: 10px 40px;

    }
}
.btn--sm{
    font-size: 14px;
    font-weight: 600;
    padding: 6px 18px;
}
.load-btn{
    display: inline-flex;
    svg{
        fill: var(--primary-color3);
        margin-left: 10px;
    }
}

.hover-border1{
    background: var(--white);
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        width: 0%;
        height: 1px;
        position: absolute;
        bottom:  0%;
        left: 0%;
        background: var(--primary-color1);
        transition: all .52s;
        z-index: -1;
    }
    &:hover{
        &::before{
            width: 100%;
        }
    }
}


/*=======================================
 5. preloader start 
=======================================*/

.egns-preloader{
    min-width: 100%;
    min-height: 100%;
    position: fixed !important;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh ;
        background: #fff;
        z-index: -1;
    }
}
.circle-border{
    width:100%;
    max-width: 140px;
    height:100%;
    max-height: 140px;
    border: 0px solid  var(--primary_color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin:auto;
    border-radius:50%;
    box-shadow: 2px 2px 30px rgba(0,0,0,0.1);
    svg {
        fill: var(--primary-color1);
        path{
            stroke: var(--primary-color1);
        }
    }
    .moving-circle{
        width:10px;
        height:10px;
        background: var(--primary-color1);
        border-radius:50%;
        position:absolute;
        top:0; bottom:0; left:0;right:0;
        overflow:hidden;
        margin:auto;
        animation: circle 2s linear infinite;
        &:nth-child(2){
            width:10px;
            height:10px;
            background: var(--primary-color1);
            border-radius:50%;
            position:absolute;
            top:0; bottom:0; left:0;right:0;
            overflow:hidden;
            margin:auto;
            animation: circle 2.25s linear infinite;
        }
        &:nth-child(3){
            width:10px;
            height:10px;
            background: var(--primary-color1);
            border-radius:50%;
            position:absolute;
            top:0; bottom:0; left:0;right:0;
            overflow:hidden;
            margin:auto;
            animation: circle 2.5s linear infinite;
        }
      }
  }
  @keyframes circle{
    0%{
      transform:rotate(0deg)
                translate(-70px)
                rotate(0deg);
    }
    100%{
      transform:rotate(360deg)
                translate(-70px)
                rotate(-360deg);
    }
  }
    $len: 242.776657104492px;
    $time: 1.6s;
#outline {
  stroke-dasharray: $len*0.01, $len;
  stroke-dashoffset: 0;
  animation: anim $time linear infinite;
}
@keyframes anim {
  12.5% {
    stroke-dasharray: $len*0.14, $len;
    stroke-dashoffset: -$len*0.11;
  }
  43.75% {
    stroke-dasharray: $len*0.35, $len;
    stroke-dashoffset: -$len*0.35;
  }
  100% {
    stroke-dasharray: $len*0.01, $len;
    stroke-dashoffset: -$len*0.99;
  }
}

/*=======================================
 6. pagination start 
=======================================*/
.pagination-wrap{
    // margin-top: 50px;
    .page-item{
        &.active {
            .page-link {
                z-index: 3;
                color: var(--white);
                background-color: var(--primary-color1);
                border-color: var(--primary-color1);
            }
        }
    }
    .pagination{
        margin: 0;
    }
    
    .page-link {
        position: relative;
        display: block;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        background-color: var(--white);
        border-radius: 5px;
        transition: 0.42s ease;
        border: 1px solid rgba(#292B31,0.3);
        border-radius: 5px;
        color: rgba(#292B31,0.3);
        padding: 7px 10px;
        svg{
            fill: var(--primary-color3);
        }
        &:focus{
            box-shadow: unset;
            color: var(--white);
            background-color: var(--primary-color1);
            border-color: var(--primary-color1);
        }
        &:hover{
            color: var(--white);
            background-color: var(--primary-color1);
            border-color: var(--primary-color1);
            svg{
                fill: var(--white);
            }
        }
    }
    .page-item.active .page-link {
        z-index: 3;
        color: var(--white);
        background-color: var(--primary-color1);
        border-color: var(--primary-color1);
    }
}

 /*=======================================
  7. search area start
=======================================*/

.mobile-search {
    background: rgba(#000, 0.85);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-items: center;
    position: fixed;
    cursor: popoppins;
    // transform: translateY(-100%);
    transform: scale(0);
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: .65s ease;
    padding: 35px 100px;

    @include md-down-device(){
        padding: 20px 20px;
    }

    label {
        color: #fff;
        margin-bottom: 20px;
        
    }
    
   &.slide {
    // transform: translateY(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  } 
  
    input {
    border: none;
    border-radius: unset;
    // border-bottom: 1px solid $border-color;
    padding-left: 0px;
    padding-bottom: 5px;
    width: 100%;
    background: transparent;
    transition: 0.3s ease-in-out;
    color: #fff;
    margin-bottom: 20px;
    &:focus{
    border-bottom: 1px solid #fff;

    }
    // &::placeholder{
    //     color: var(--dark);
    // }
  }
  
  .search-cross-btn {
    color: #fff;
    cursor: popoppins;
    background: rgba(var(--primary-color1), 0.6);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    line-height: 43px;
    transition: 0.5s ease;
    &:hover{
        background: var(--primary-color1);
        color: #fff;
    }
}
  .search-cross-btn i {
    font-size: 25px;
    cursor: popoppins;
  }
}  


/*=======================================
   8. Mobile-menu Css
=======================================*/

.mobile-menu-form{
    margin-top: 50px;
    input{
        width: 100%;
        // border: 1px solid $border-color;
        padding: 10px 15px;
        background-color: var(--white);
        margin-bottom: 15px;
        outline: none;
        border-radius: 5px;
        &::placeholder{
            font-size: 12px;
        }
        &.style-capsule{
            border-radius: 30px;
        }
    }
    button{
        outline: none;
        border: none;
        padding: 10px;
    }
    &.style-2{
        input{
            border-radius: 30px;
        }
    }
}

/*================================================
 9. Topbar-section
=================================================*/
.topbar{
    padding: 10px 8%;
    @include lg-device(){
        padding: 10px 3%;
    }
    @include lg-down-device(){
        display: none;
        visibility: hidden;
    }
    .appontment{
        h6{
            margin-bottom: 0px;
            font-size: 15px;
            font-weight: 500;
            color: var(--text-color);
            a{
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
}
.phone-call2{
    .number{
        text-align: left;
        span{
            display: inline-block;
            color: var(--text-color2);
            font-family: var(--font-exo);
            font-size: 12px;
            font-weight: 500;
        }
        h5{
            margin-bottom: 0px;
            margin-top: -7px;
            white-space: nowrap;
            a{
                color: var(--text-color1);
                font-size: 15px;
                font-weight: 700;
                margin-bottom: 0px;
            }
        }
    }
}
/*================================================
 10. Header-section
=================================================*/
li{
    a.active-menu{
        color: var(--primary-color1) !important;
    }
}
header.style-1 {
    background-color: rgba(#0B0F14,1);
    width: 100%;
    z-index: 99;
    padding: 0px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    
    @include xl-device(){
        padding: 0px 30px;
    }
    @include lg-device(){
        padding: 0px 30px;
    }

    @include lg-down-device(){
        padding: 0px 20px;
        top: 0;
    }
    &.sticky{
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 99;
        background: var(--dark--bg);
        // transition: 0.2s ease;
        box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
        animation: smooth-header 0.65s linear;        
        
        @keyframes smooth-header {
            0%{
                transform: translateY(-30px);
            }
            100%{
                transform: translateY(0px);
            }
            
        }
    }

    // end navbar-right
    .header-logo{
        @include xl-device(){
            padding: 21px 0px;
        }
        @include lg-device(){
            padding: 21px 0px;
        }
        @include lg-down-device(){
            padding: 20px 0px;
        }

        img{
            min-width: 120px;
            max-width: 100%;
        }
    }
    @media only screen and (min-width: 1199px) {
        .main-nav ul li:hover ul.sub-menu {
          display: block !important;
          transition: all linear 0.3s;
        }
      }
    .main-nav {
        display: inline-block;
        .mobile-menu-logo {
            display: none;
        }
        ul {
            list-style: none;
            margin:0;
            padding: 0;
            li {
                display: inline-block;
                position: relative;
                padding: 0 22px;
                a {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 500;
                    display: block;
                    text-transform: capitalize;
                    padding: 30px 0;
                    position: relative;
                    -webkit-transition: all .5s ease-out 0s;
                    -moz-transition: all .5s ease-out 0s;
                    -ms-transition: all .5s ease-out 0s;
                    -o-transition: all .5s ease-out 0s;
                    transition: all .5s ease-out 0s;
                    &:hover {
                        color: var(--primary-color1);
                    }
                }
                a.active {
                    color: var(--primary-color1);
                }
                i {
                    width: 30px;
                    font-size: 14px;
                    text-align: center;
                    color: #fff;
                    font-style: normal;
                    position: absolute;
                    right: -6px;
                    top: 33px;
                    z-index: 999;
                    cursor: pointer;
                    display: none;
                }
                ul.sub-menu {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: auto;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    min-width: 215px;
                    background: #0B0F14;
                    text-align: left;
                    transition: all .5s ease-out 0s;
                    transform: translateY(20px);
                    &::after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary-color1);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    > li {
                        i {
                            position: absolute;
                            top: 10px;
                            right: 6px;
                            display: block;
                        }
                        padding: 0;
                        display: block;
                        border-bottom: 1px solid rgba(#383838,0.5);
                        position: relative;
                        a {
                            display: block;
                            padding: 15px 15px;
                            color: #fff;
                            font-weight: 300;
                            text-transform: capitalize;
                            font-size: 15px;
                            line-height: 1;
                            -webkit-transition: all .4s ease-out 0s;
                            transition: all .4s ease-out 0s;
                            &:hover {
                                color: var(--primary-color1);
                                margin-left: 10px;
                            }
                        }
                        a.active {
                            color: var(--primary-color1);
                        }
                        .sub-menu{
                            left: 215px;
                            position: absolute;
                            max-width: 230px;
                            min-width: 215px;
                            background: #0B0F14;
                            top: 0;
                            @media only screen and (max-width: 1199px) {
                                margin-left: 10px;
                                position: unset;
                                max-width: 230px;
                                min-width: 215px;
                                background: transparent;
                                top: 0;
                            }
                            li i {
                                display: block;
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
                &:hover {
                    > ul.sub-menu {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
            li.menu-item-has-children > i {
                display: block;
            }
        }
    }
    @media only screen and (max-width: 1199px) {

            .main-nav {
                position: fixed;
                top: 0;
                left: 0;
                width: 280px;
                padding: 30px 20px !important;
                z-index: 99999;
                height: 100%;
                overflow: auto;
                background: var(--dark-bg);
                -webkit-transform: translateX(-260px);
                transform: translateX(-100%);
                -webkit-transition: -webkit-transform .3s ease-in;
                transition: -webkit-transform .3s ease-in;
                transition: transform .3s ease-in;
                transition: transform .3s ease-in, -webkit-transform .3s ease-in;
                &.show-menu{
                    transform: translateX(0);
                }
                .mobile-menu-logo {
                    text-align: left;
                    padding-top: 20px;
                    display: block;
                    border-bottom: 1px solid transparent;
                    padding-bottom: 8px;
                }
                ul {
                    float: none;
                    text-align: left;
                    padding: 25px 10px 25px 0;
                    li {
                        display: block;
                        position: relative;
                        padding: 0 5px;
                        i{
                            display: block;
                        }
                        a {
                            padding: 10px 0;
                            display: block;
                            font-weight: 300;
                        }
                        ul.sub-menu {
                            position: static;
                            min-width: 200px;
                            background: 0 0;
                            border: none;
                            opacity: 1;
                            visibility: visible;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            -webkit-transform: none;
                            transform: none;
                            -webkit-transition: none;
                            transition: none;
                            display: none;
                            margin-top: 0 !important;
                            transform: translateY(0px);
                        }
                        .bi {
                            top: 8px;
                            font-size: 16px;
                        }
                    }
                }
            }
            // .main-nav.slidenav {
            //     -webkit-transform: translateX(0);
                
            // }
        
        .mobile-menu {
            position: relative;
            top: 2px;
            padding: 0 5px;
            border-radius: 50%;
            display: inline-block;
        }
        .cross-btn {
            display: inline-block !important;
            position: relative;
            width: 30px !important;
            height: 22px !important;
            cursor: pointer;
            border: 3px solid transparent !important;
            span {
                width: 100%;
                height: 2px;
                background: linear-gradient(233.77deg, rgba(115, 0, 0, 0.8) .94%, rgba(217, 10, 44, 0.8) 99.09%) !important;
                display: block;
                position: absolute;
                right: 0;
                -webkit-transition: all .3s;
                transition: all .3s;
            }
            .cross-top {
                top: 0;
            }
            .cross-middle {
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 100%;
            }
            .cross-bottom {
                bottom: 0;
                width: 100%;
            }
        }
        .cross-btn.h-active {
            span.cross-top {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 50%;
                margin-top: -1px;
            }
            span.cross-middle {
                -webkit-transform: translateX(-30px);
                transform: translateX(-30px);
                opacity: 0;
            }
            span.cross-bottom {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                bottom: 50%;
                margin-bottom: -1px;
            }
        }
    }

}
.mobile-menu-btn{
    i{
        font-size: 22px;
        color: #fff;
    }
}
.nav-left{
    position: relative;
    z-index: 99;
    .mobile-menu-btn{
        i{
            font-size: 22px;
        }
    } 
}

// header2
header.style-2 {
    background-color: var(--green);
    width: 100%;
    z-index: 99;
    padding: 0px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    
    @include xl-device(){
        padding: 0px 2%;
    }
    @include lg-device(){
        padding: 0px 4%;
    }
    @include lg-down-device(){
        padding: 0px 20px;
        top: 0;
    }
    &.sticky{
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 99;
        background: var(--dark--bg);
        // transition: 0.2s ease;
        box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
        animation: smooth-header 0.65s linear;        
        
        @keyframes smooth-header {
            0%{
                transform: translateY(-30px);
            }
            100%{
                transform: translateY(0px);
            }
            
        }
    }

    // end navbar-right
    .header-logo{
        @include xl-device(){
            padding: 21px 0px;
        }
        @include lg-device(){
            padding: 21px 0px;
        }
        @include lg-down-device(){
            padding: 20px 0px;
        }

        img{
            min-width: 120px;
            max-width: 100%;
        }
    }
    @media only screen and (min-width: 1000px) {
        .main-nav ul li:hover ul.sub-menu {
          display: block !important;
          transition: all linear 0.3s;
        }
      }
    .main-nav {
        display: inline-block;
        .mobile-menu-logo {
            display: none;
        }
        ul {
            list-style: none;
            margin:0;
            padding: 0;
            li {
                display: inline-block;
                position: relative;
                padding: 0 22px;
                a {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 500;
                    display: block;
                    text-transform: capitalize;
                    padding: 30px 0;
                    position: relative;
                    -webkit-transition: all .5s ease-out 0s;
                    -moz-transition: all .5s ease-out 0s;
                    -ms-transition: all .5s ease-out 0s;
                    -o-transition: all .5s ease-out 0s;
                    transition: all .5s ease-out 0s;
                    &:hover {
                        color: var(--primary-color2);
                    }
                }
                a.active {
                    color: var(--primary-color2);
                }
                i {
                    width: 30px;
                    font-size: 14px;
                    text-align: center;
                    color: #fff;
                    font-style: normal;
                    position: absolute;
                    right: -6px;
                    top: 33px;
                    z-index: 999;
                    cursor: pointer;
                    display: none;
                }
                ul.sub-menu {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: auto;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    min-width: 215px;
                    background: #0B0F14;
                    text-align: left;
                    transition: all .5s ease-out 0s;
                    transform: translateY(20px);
                    &::after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary-color2);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    > li {
                        i {
                            position: absolute;
                            top: 10px;
                            right: 6px;
                            display: block;
                        }
                        padding: 0;
                        display: block;
                        border-bottom: 1px solid rgba(#383838,0.5);
                        position: relative;
                        a {
                            display: block;
                            padding: 15px 15px;
                            color: #fff;
                            font-weight: 300;
                            text-transform: capitalize;
                            font-size: 15px;
                            line-height: 1;
                            -webkit-transition: all .4s ease-out 0s;
                            transition: all .4s ease-out 0s;
                            &:hover {
                                color: var(--primary-color2);
                                margin-left: 10px;
                            }
                        }
                        a.active {
                            color: var(--primary-color2);
                        }
                        .sub-menu{
                            left: 215px;
                            position: absolute;
                            max-width: 230px;
                            min-width: 215px;
                            background: #0B0F14;
                            top: 0;
                            @media only screen and (max-width: 1199px) {
                                margin-left: 10px;
                                position: unset;
                                max-width: 230px;
                                min-width: 215px;
                                background: transparent;
                                top: 0;
                            }
                            li i {
                                display: block;
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
                &:hover {
                    > ul.sub-menu {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
            li.menu-item-has-children > i {
                display: block;
            }
        }
    }
    @media only screen and (max-width: 1199px) {

            .main-nav {
                position: fixed;
                top: 0;
                left: 0;
                width: 280px;
                padding: 30px 20px !important;
                z-index: 99999;
                height: 100%;
                overflow: auto;
                background: var(--green);
                -webkit-transform: translateX(-260px);
                transform: translateX(-100%);
                -webkit-transition: -webkit-transform .3s ease-in;
                transition: -webkit-transform .3s ease-in;
                transition: transform .3s ease-in;
                transition: transform .3s ease-in, -webkit-transform .3s ease-in;
                &.show-menu{
                    transform: translateX(0);
                }
                .mobile-menu-logo {
                    text-align: left;
                    padding-top: 20px;
                    display: block;
                    border-bottom: 1px solid #D90A2C;
                    padding-bottom: 8px;
                }
                ul {
                    float: none;
                    text-align: left;
                    padding: 25px 10px 25px 0;
                    li {
                        display: block;
                        position: relative;
                        padding: 0 5px;
                        i{
                            display: block;
                        }
                        a {
                            padding: 10px 0;
                            display: block;
                            font-weight: 300;
                        }
                        ul.sub-menu {
                            position: static;
                            min-width: 200px;
                            background: 0 0;
                            border: none;
                            opacity: 1;
                            visibility: visible;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            -webkit-transform: none;
                            transform: none;
                            -webkit-transition: none;
                            transition: none;
                            display: none;
                            margin-top: 0 !important;
                            transform: translateY(0px);
                        }
                        .bi {
                            top: 8px;
                            font-size: 16px;
                        }
                    }
                }
            }
            // .main-nav.slidenav {
            //     -webkit-transform: translateX(0);
                
            // }
        
        .mobile-menu {
            position: relative;
            top: 2px;
            padding: 0 5px;
            border-radius: 50%;
            display: inline-block;
        }
        .cross-btn {
            display: inline-block !important;
            position: relative;
            width: 30px !important;
            height: 22px !important;
            cursor: pointer;
            border: 3px solid transparent !important;
            span {
                width: 100%;
                height: 2px;
                background: linear-gradient(233.77deg, rgba(115, 0, 0, 0.8) .94%, rgba(217, 10, 44, 0.8) 99.09%) !important;
                display: block;
                position: absolute;
                right: 0;
                -webkit-transition: all .3s;
                transition: all .3s;
            }
            .cross-top {
                top: 0;
            }
            .cross-middle {
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 100%;
            }
            .cross-bottom {
                bottom: 0;
                width: 100%;
            }
        }
        .cross-btn.h-active {
            span.cross-top {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 50%;
                margin-top: -1px;
            }
            span.cross-middle {
                -webkit-transform: translateX(-30px);
                transform: translateX(-30px);
                opacity: 0;
            }
            span.cross-bottom {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                bottom: 50%;
                margin-bottom: -1px;
            }
        }
    }
}

// header3
header.style-3 {
    background-color: transparent;
    width: 100%;
    z-index: 99;
    padding: 0px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    @include xl-device(){
        padding: 0px 2%;
    }
    @include lg-device(){
        padding: 0px 4%;
    }
    @include md-device(){
        padding: 0px 4%;
        top: 0;
    }
    @include md-down-device(){
        top: 0;
        padding: 0px 4%;
    }
    
    &.sticky{
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 99;
        background: var(--dark--bg);
        // transition: 0.2s ease;
        box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
        animation: smooth-header 0.65s linear;        
        
        @keyframes smooth-header {
            0%{
                transform: translateY(-30px);
            }
            100%{
                transform: translateY(0px);
            }
            
        }
    }

    // end navbar-right
    .header-logo{
        margin-bottom: 2px;
        @include xl-device(){
            padding: 21px 0px;
        }
        @include lg-device(){
            padding: 21px 0px;
        }
        @include lg-down-device(){
            padding: 21px 0px;
        }

        img{
            min-width: 120px;
            max-width: 100%;
        }
    }
    @media only screen and (min-width: 1100px) {
        .main-nav ul li:hover ul.sub-menu {
          display: block !important;
          transition: all linear 0.3s;
        }
      }
    .main-nav {
        display: inline-block;
        .mobile-menu-logo {
            display: none;
        }
        ul {
            list-style: none;
            margin:0;
            padding: 0;
            li {
                display: inline-block;
                position: relative;
                padding: 0 22px;
                a {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 500;
                    line-height: 26px;
                    display: block;
                    text-transform: capitalize;
                    padding: 30px 0;
                    position: relative;
                    -webkit-transition: all .5s ease-out 0s;
                    -moz-transition: all .5s ease-out 0s;
                    -ms-transition: all .5s ease-out 0s;
                    -o-transition: all .5s ease-out 0s;
                    transition: all .5s ease-out 0s;
                    &:hover {
                        color: var(--primary-color2);
                    }
                }
                a.active {
                    color: var(--primary-color2);
                }
                i {
                    width: 30px;
                    font-size: 14px;
                    text-align: center;
                    color: #fff;
                    font-style: normal;
                    position: absolute;
                    right: -6px;
                    top: 33px;
                    z-index: 999;
                    cursor: pointer;
                    display: none;
                }
                ul.sub-menu {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: auto;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    min-width: 215px;
                    background: #0B0F14;
                    text-align: left;
                    transition: all .5s ease-out 0s;
                    transform: translateY(20px);
                    &::after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary-color2);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    > li {
                        i {
                            position: absolute;
                            top: 10px;
                            right: 6px;
                            display: block;
                        }
                        padding: 0;
                        display: block;
                        border-bottom: 1px solid rgba(#383838,0.5);
                        position: relative;
                        a {
                            display: block;
                            padding: 15px 15px;
                            color: #fff;
                            font-weight: 300;
                            text-transform: capitalize;
                            font-size: 15px;
                            line-height: 1;
                            -webkit-transition: all .4s ease-out 0s;
                            transition: all .4s ease-out 0s;
                            &:hover {
                                color: var(--primary-color2);
                                margin-left: 10px;
                            }
                        }
                        a.active {
                            color: var(--primary-color2);
                        }
                        .sub-menu{
                            left: 215px;
                            position: absolute;
                            max-width: 230px;
                            min-width: 215px;
                            background: #0B0F14;
                            top: 0;
                            @media only screen and (max-width: 1199px) {
                                margin-left: 10px;
                                position: unset;
                                max-width: 230px;
                                min-width: 215px;
                                background: transparent;
                                top: 0;
                            }
                            li i {
                                display: block;
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
                &:hover {
                    > ul.sub-menu {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
            li.menu-item-has-children > i {
                display: block;
            }
        }
    }
    @media only screen and (max-width: 1199px) {

            .main-nav {
                position: fixed;
                top: 0;
                left: 0;
                width: 280px;
                padding: 30px 20px !important;
                z-index: 99999;
                height: 100%;
                overflow: auto;
                background: var(--green);
                -webkit-transform: translateX(-260px);
                transform: translateX(-100%);
                -webkit-transition: -webkit-transform .3s ease-in;
                transition: -webkit-transform .3s ease-in;
                transition: transform .3s ease-in;
                transition: transform .3s ease-in, -webkit-transform .3s ease-in;
                &.show-menu{
                    transform: translateX(0);
                }
                .mobile-menu-logo {
                    text-align: left;
                    padding-top: 20px;
                    display: block;
                    border-bottom: 1px solid #D90A2C;
                    padding-bottom: 8px;
                }
                ul {
                    float: none;
                    text-align: left;
                    padding: 25px 10px 25px 0;
                    li {
                        display: block;
                        position: relative;
                        padding: 0 5px;
                        i{
                            display: block;
                        }
                        a {
                            padding: 10px 0;
                            display: block;
                            font-weight: 300;
                        }
                        ul.sub-menu {
                            position: static;
                            min-width: 200px;
                            background: 0 0;
                            border: none;
                            opacity: 1;
                            visibility: visible;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            -webkit-transform: none;
                            transform: none;
                            -webkit-transition: none;
                            transition: none;
                            display: none;
                            margin-top: 0 !important;
                            transform: translateY(0px);
                        }
                        .bi {
                            top: 8px;
                            font-size: 16px;
                        }
                    }
                }
            }
            // .main-nav.slidenav {
            //     -webkit-transform: translateX(0);
                
            // }
        
        .mobile-menu {
            position: relative;
            top: 2px;
            padding: 0 5px;
            border-radius: 50%;
            display: inline-block;
        }
        .cross-btn {
            display: inline-block !important;
            position: relative;
            width: 30px !important;
            height: 22px !important;
            cursor: pointer;
            border: 3px solid transparent !important;
            span {
                width: 100%;
                height: 2px;
                background: linear-gradient(233.77deg, rgba(115, 0, 0, 0.8) .94%, rgba(217, 10, 44, 0.8) 99.09%) !important;
                display: block;
                position: absolute;
                right: 0;
                -webkit-transition: all .3s;
                transition: all .3s;
            }
            .cross-top {
                top: 0;
            }
            .cross-middle {
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 100%;
            }
            .cross-bottom {
                bottom: 0;
                width: 100%;
            }
        }
        .cross-btn.h-active {
            span.cross-top {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 50%;
                margin-top: -1px;
            }
            span.cross-middle {
                -webkit-transform: translateX(-30px);
                transform: translateX(-30px);
                opacity: 0;
            }
            span.cross-bottom {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                bottom: 50%;
                margin-bottom: -1px;
            }
        }
    }
}

header.style-4 {
    // background-color: #1B1B22;
    width: 100%;
    z-index: 99;
    padding: 0px 60px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include xl-down-device(){
        padding: 0;
    }
    .nav-left{
        background-color:  #151515;
        width: 100%;
        max-width: 1545px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 60px;
        padding-right: 60px;
        justify-content: space-between;
        @media (min-width:1400px) and (max-width: 1830px){
            max-width: 1350px;
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
        }
        @include xl-device(){
            max-width: 1050px;
            padding-left: 20px;
            padding-right: 20px;
        }
        @include lg-down-device(){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .nav-right{
        background-color: var(--white);
    }
    
    &.sticky{
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 99;
        background: var(--dark--bg);
        // transition: 0.2s ease;
        box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
        animation: smooth-header 0.65s linear;        
        
        @keyframes smooth-header {
            0%{
                transform: translateY(-30px);
            }
            100%{
                transform: translateY(0px);
            }
            
        }
        .nav-right{
            background-color:  #151515;
            .phone-call{
                .icon{
                    svg{
                        fill: var(--white);
                    }
                }
                .number{
                    span{
                        color: var(--white);
                    }
                    h5{
                        white-space: nowrap;
                        a{
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }

    // end navbar-right
    .header-logo{
        @include xl-device(){
            padding: 21px 0px;
        }
        @include lg-device(){
            padding: 21px 0px;
        }
        @include lg-down-device(){
            padding: 20px 0px;
        }

        img{
            min-width: 120px;
            max-width: 100%;
        }
    }
    @media only screen and (min-width: 1100px) {
        .main-nav ul li:hover ul.sub-menu {
          display: block !important;
          transition: all linear 0.3s;
        }
      }
    .main-nav {
        display: inline-block;
        .mobile-menu-logo {
            display: none;
        }
        ul {
            list-style: none;
            margin:0;
            padding: 0;
            li {
                display: inline-block;
                position: relative;
                padding: 0 17px;
                a {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 500;
                    display: block;
                    text-transform: capitalize;
                    padding: 30px 0;
                    position: relative;
                    -webkit-transition: all .5s ease-out 0s;
                    -moz-transition: all .5s ease-out 0s;
                    -ms-transition: all .5s ease-out 0s;
                    -o-transition: all .5s ease-out 0s;
                    transition: all .5s ease-out 0s;
                    &:hover {
                        color: var(--primary-color3);
                    }
                }
                a.active {
                    color: var(--primary-color3);
                }
                i {
                    width: 30px;
                    font-size: 14px;
                    text-align: center;
                    color: #fff;
                    font-style: normal;
                    position: absolute;
                    right: -8px;
                    top: 33px;
                    z-index: 999;
                    cursor: pointer;
                    display: none;
                }
                ul.sub-menu {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: auto;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    min-width: 215px;
                    background: #0B0F14;
                    text-align: left;
                    transition: all .5s ease-out 0s;
                    transform: translateY(20px);
                    &::after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary-color3);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    > li {
                        i {
                            position: absolute;
                            top: 10px;
                            right: 6px;
                            display: block;
                        }
                        padding: 0;
                        display: block;
                        border-bottom: 1px solid rgba(#383838,0.5);
                        position: relative;
                        a {
                            display: block;
                            padding: 15px 15px;
                            color: #fff;
                            font-weight: 300;
                            text-transform: capitalize;
                            font-size: 15px;
                            line-height: 1;
                            -webkit-transition: all .4s ease-out 0s;
                            transition: all .4s ease-out 0s;
                            &:hover {
                                color: var(--primary-color3);
                                margin-left: 10px;
                            }
                        }
                        a.active {
                            color: var(--primary-color3);
                        }
                        .sub-menu{
                            left: 215px;
                            position: absolute;
                            max-width: 230px;
                            min-width: 215px;
                            background: #0B0F14;
                            top: 0;
                            @media only screen and (max-width: 1199px) {
                                margin-left: 10px;
                                position: unset;
                                max-width: 230px;
                                min-width: 215px;
                                background: transparent;
                                top: 0;
                            }
                            li i {
                                display: block;
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
                &:hover {
                    > ul.sub-menu {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
            li.menu-item-has-children > i {
                display: block;
            }
        }
    }
    @media only screen and (max-width: 1199px) {

            .main-nav {
                position: fixed;
                top: 0;
                left: 0;
                width: 280px;
                padding: 30px 20px !important;
                z-index: 99999;
                height: 100%;
                overflow: auto;
                background: var(--dark-bg);
                -webkit-transform: translateX(-260px);
                transform: translateX(-100%);
                -webkit-transition: -webkit-transform .3s ease-in;
                transition: -webkit-transform .3s ease-in;
                transition: transform .3s ease-in;
                transition: transform .3s ease-in, -webkit-transform .3s ease-in;
                &.show-menu{
                    transform: translateX(0);
                }
                .mobile-menu-logo {
                    text-align: left;
                    padding-top: 20px;
                    display: block;
                    // border-bottom: 1px solid #D90A2C;
                    padding-bottom: 8px;
                }
                ul {
                    float: none;
                    text-align: left;
                    padding: 25px 10px 25px 0;
                    li {
                        display: block;
                        position: relative;
                        padding: 0 5px;
                        i{
                            display: block;
                        }
                        a {
                            padding: 10px 0;
                            display: block;
                            font-weight: 300;
                        }
                        ul.sub-menu {
                            position: static;
                            min-width: 200px;
                            background: 0 0;
                            border: none;
                            opacity: 1;
                            visibility: visible;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            -webkit-transform: none;
                            transform: none;
                            -webkit-transition: none;
                            transition: none;
                            display: none;
                            margin-top: 0 !important;
                            transform: translateY(0px);
                        }
                        .bi {
                            top: 8px;
                            font-size: 16px;
                        }
                    }
                }
            }
            // .main-nav.slidenav {
            //     -webkit-transform: translateX(0);
                
            // }
        
        .mobile-menu {
            position: relative;
            top: 2px;
            padding: 0 5px;
            border-radius: 50%;
            display: inline-block;
        }
        .cross-btn {
            display: inline-block !important;
            position: relative;
            width: 30px !important;
            height: 22px !important;
            cursor: pointer;
            border: 3px solid transparent !important;
            span {
                width: 100%;
                height: 2px;
                background: linear-gradient(233.77deg, rgba(115, 0, 0, 0.8) .94%, rgba(217, 10, 44, 0.8) 99.09%) !important;
                display: block;
                position: absolute;
                right: 0;
                -webkit-transition: all .3s;
                transition: all .3s;
            }
            .cross-top {
                top: 0;
            }
            .cross-middle {
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 100%;
            }
            .cross-bottom {
                bottom: 0;
                width: 100%;
            }
        }
        .cross-btn.h-active {
            span.cross-top {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 50%;
                margin-top: -1px;
            }
            span.cross-middle {
                -webkit-transform: translateX(-30px);
                transform: translateX(-30px);
                opacity: 0;
            }
            span.cross-bottom {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                bottom: 50%;
                margin-bottom: -1px;
            }
        }
    }
}
header.style-5 {
    background-color: #1B1B22;
    width: 100%;
    z-index: 99;
    padding: 0px 60px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include xl-down-device(){
        padding: 0;
    }
    @media only screen and (min-width: 1100px) {
        .main-nav ul li:hover ul.sub-menu {
          display: block !important;
          transition: all linear 0.3s;
        }
      }
    .nav-left{
        background-color:  #151515;
        width: 100%;
        max-width: 1545px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 60px;
        padding-right: 60px;
        justify-content: space-between;
        @media (min-width:1400px) and (max-width: 1830px){
            max-width: 1350px;
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
        }
        @include xl-device(){
            max-width: 1050px;
            padding-left: 20px;
            padding-right: 20px;
        }
        @include lg-down-device(){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .nav-right{ 
        background-color: #1B1B22;
    }
    
    &.sticky{
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 99;
        background: var(--dark--bg);
        // transition: 0.2s ease;
        box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
        animation: smooth-header 0.65s linear;        
        
        @keyframes smooth-header {
            0%{
                transform: translateY(-30px);
            }
            100%{
                transform: translateY(0px);
            }
            
        }
        .nav-right{
            background-color:  #151515;
            .phone-call{
                .icon{
                    svg{
                        fill: var(--white);
                    }
                }
                .number{
                    span{
                        color: var(--white);
                    }
                    h5{
                        white-space: nowrap;
                        a{
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }

    // end navbar-right
    .header-logo{
        @include xl-device(){
            padding: 21px 0px;
        }
        @include lg-device(){
            padding: 21px 0px;
        }
        @include lg-down-device(){
            padding: 20px 0px;
        }

        img{
            min-width: 120px;
            max-width: 100%;
        }
    }

    .main-nav {
        display: inline-block;
        .mobile-menu-logo {
            display: none;
        }
        ul {
            list-style: none;
            margin:0;
            padding: 0;
            li {
                display: inline-block;
                position: relative;
                padding: 0 17px;
                a {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 500;
                    display: block;
                    text-transform: capitalize;
                    padding: 30px 0;
                    position: relative;
                    -webkit-transition: all .5s ease-out 0s;
                    -moz-transition: all .5s ease-out 0s;
                    -ms-transition: all .5s ease-out 0s;
                    -o-transition: all .5s ease-out 0s;
                    transition: all .5s ease-out 0s;
                    &:hover {
                        color: var(--primary-color3);
                    }
                }
                a.active {
                    color: var(--primary-color3);
                }
                i {
                    width: 30px;
                    font-size: 14px;
                    text-align: center;
                    color: #fff;
                    font-style: normal;
                    position: absolute;
                    right: -8px;
                    top: 33px;
                    z-index: 999;
                    cursor: pointer;
                    display: none;
                }
                ul.sub-menu {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: auto;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    min-width: 215px;
                    background: #0B0F14;
                    text-align: left;
                    transition: all .5s ease-out 0s;
                    transform: translateY(20px);
                    &::after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary-color3);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    > li {
                        i {
                            position: absolute;
                            top: 10px;
                            right: 6px;
                            display: block;
                        }
                        padding: 0;
                        display: block;
                        border-bottom: 1px solid rgba(#383838,0.5);
                        position: relative;
                        a {
                            display: block;
                            padding: 15px 15px;
                            color: #fff;
                            font-weight: 300;
                            text-transform: capitalize;
                            font-size: 15px;
                            line-height: 1;
                            -webkit-transition: all .4s ease-out 0s;
                            transition: all .4s ease-out 0s;
                            &:hover {
                                color: var(--primary-color3);
                                margin-left: 10px;
                            }
                        }
                        a.active {
                            color: var(--primary-color3);
                        }
                        .sub-menu{
                            left: 215px;
                            position: absolute;
                            max-width: 230px;
                            min-width: 215px;
                            background: #0B0F14;
                            top: 0;
                            @media only screen and (max-width: 1199px) {
                                margin-left: 10px;
                                position: unset;
                                max-width: 230px;
                                min-width: 215px;
                                background: transparent;
                                top: 0;
                            }
                            li i {
                                display: block;
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
                &:hover {
                    > ul.sub-menu {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
            li.menu-item-has-children > i {
                display: block;
            }
        }
    }
    @media only screen and (max-width: 1199px) {

            .main-nav {
                position: fixed;
                top: 0;
                left: 0;
                width: 280px;
                padding: 30px 20px !important;
                z-index: 99999;
                height: 100%;
                overflow: auto;
                background: var(--dark-bg);
                -webkit-transform: translateX(-260px);
                transform: translateX(-100%);
                -webkit-transition: -webkit-transform .3s ease-in;
                transition: -webkit-transform .3s ease-in;
                transition: transform .3s ease-in;
                transition: transform .3s ease-in, -webkit-transform .3s ease-in;
                &.show-menu{
                    transform: translateX(0);
                }
                .mobile-menu-logo {
                    text-align: left;
                    padding-top: 20px;
                    display: block;
                    // border-bottom: 1px solid #D90A2C;
                    padding-bottom: 8px;
                }
                ul {
                    float: none;
                    text-align: left;
                    padding: 25px 10px 25px 0;
                    li {
                        display: block;
                        position: relative;
                        padding: 0 5px;
                        i{
                            display: block;
                        }
                        a {
                            padding: 10px 0;
                            display: block;
                            font-weight: 300;
                        }
                        ul.sub-menu {
                            position: static;
                            min-width: 200px;
                            background: 0 0;
                            border: none;
                            opacity: 1;
                            visibility: visible;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            -webkit-transform: none;
                            transform: none;
                            -webkit-transition: none;
                            transition: none;
                            display: none;
                            margin-top: 0 !important;
                            transform: translateY(0px);
                        }
                        .bi {
                            top: 8px;
                            font-size: 16px;
                        }
                    }
                }
            }
            // .main-nav.slidenav {
            //     -webkit-transform: translateX(0);
                
            // }
        
        .mobile-menu {
            position: relative;
            top: 2px;
            padding: 0 5px;
            border-radius: 50%;
            display: inline-block;
        }
        .cross-btn {
            display: inline-block !important;
            position: relative;
            width: 30px !important;
            height: 22px !important;
            cursor: pointer;
            border: 3px solid transparent !important;
            span {
                width: 100%;
                height: 2px;
                background: linear-gradient(233.77deg, rgba(115, 0, 0, 0.8) .94%, rgba(217, 10, 44, 0.8) 99.09%) !important;
                display: block;
                position: absolute;
                right: 0;
                -webkit-transition: all .3s;
                transition: all .3s;
            }
            .cross-top {
                top: 0;
            }
            .cross-middle {
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 100%;
            }
            .cross-bottom {
                bottom: 0;
                width: 100%;
            }
        }
        .cross-btn.h-active {
            span.cross-top {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 50%;
                margin-top: -1px;
            }
            span.cross-middle {
                -webkit-transform: translateX(-30px);
                transform: translateX(-30px);
                opacity: 0;
            }
            span.cross-bottom {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                bottom: 50%;
                margin-bottom: -1px;
            }
        }
    }
}
/*=======================================
   11. Banner-section
=======================================*/
.swiper-thumbs .swiper-wrapper {
    justify-content: center;
}

.phone-call{
    .icon{
        margin-right: 12px;
        svg{
            fill: var(--white);
        }
    }
    .number{
        text-align: left;
        span{
            display: inline-block;
            color: var(--white);
            font-family: var(--font-exo);
            margin-bottom: 2px;
        }
        h5{
            color: var(--white);
            font-size: 1.25rem;
            font-weight: 700;
            margin-bottom: 0px;
            white-space: nowrap;
        }
    }
    &.sibling2{
        svg{
            fill: var(--dark3);
        }
        .number{
            text-align: left;
            span{
                display: inline-block;
                color: var(--dark3);
                font-family: var(--font-exo);
                margin-bottom: 2px;
            }
            h5{
                color: var(--dark3);
                font-size: 1.25rem;
                font-weight: 700;
                margin-bottom: 0px;
            }
        }
    }
}
.banner-social-area{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    @include flex(center);
    background: var(--white);
    width: 80px;
    z-index: 1;
    @include xl-device(){
        width: 60px;
    }
    @include lg-device(){
        width: 60px;
    }
    @include lg-down-device(){
        display: none;
        visibility: hidden;
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 40px;
        width: 1px;
        height: 100%;
        background-color: var(--primary-color1);
        z-index: -1;
        opacity: 0.3;
        @include xl-device(){
            left: 30px;
        }
        @include lg-device(){
            left: 30px;
        }
    }
    ul.banner-social{
        margin: 0;
        padding: 0;
        list-style: none;
        @include flex(center);
        flex-direction: column;
        li{
            background: var(--white);
            padding: 5px;
            a{
                i{
                    font-size: 1.1rem;
                    transition: all 0.35s ease-in;
            }
            &:hover{
                i{
                    color: var(--primary-color1);
                }
            }
        }
    }
    }
}
.banner-social-area-dark{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    @include flex(center);
    background: var(--dark3);
    width: 80px;
    z-index: 9;
    @include xl-device(){
        width: 60px;
    }
    @include lg-device(){
        width: 60px;
    }
    @include lg-down-device(){
        display: none;
        visibility: hidden;
    }
    ul.banner-social{
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        @include flex(center);
        flex-direction: column;
        li{
            // background: var(--white);
            padding: 5px;
            a{
                i{
                    font-size: 1.1rem;
                    transition: all 0.35s ease-in;
                    color: var(--white);
            }
            &:hover{
                    i{
                        color: var(--primary-color3);
                    }
                }
            }
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: 100%;
            background-color: var(--primary-color1);
            z-index: -1;
            opacity: 0.3;
        }
    }
}
.banner-social-area-light{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    @include flex(center);
    background: var(--white);
    width: 80px;
    z-index: 9;
    @include xl-device(){
        width: 60px;
    }
    @include lg-device(){
        width: 60px;
        display: none;
        visibility: hidden;
    }
    @include lg-down-device(){
        display: none;
        visibility: hidden;
    }
    ul.banner-social{
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        @include flex(center);
        flex-direction: column;
        li{
            // background: var(--white);
            padding: 5px;
            a{
                i{
                    font-size: 1.1rem;
                    transition: all 0.35s ease-in;
                    color: var(--dark3);
            }
            &:hover{
                    i{
                        color: var(--primary-color3);
                    }
                }
            }
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: 100%;
            background-color: var(--primary-color1);
            z-index: -1;
            opacity: 0.3;
        }
    }
}
.banner-scroll-area{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    @include flex(center);
    background: var(--white);
    width: 80px;
    z-index: 1;
    @include xl-device(){
        width: 60px;
    }
    @include lg-device(){
        width: 60px;
    }
    @include lg-down-device(){
        display: none;
        visibility: hidden;
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 40px;
        width: 1px;
        height: 100%;
        background-color: var(--primary-color1);
        z-index: -1;
        opacity: 0.3;
        @include xl-device(){
            left: 30px;
        }
        @include lg-device(){
            left: 30px;
        }
    }
    a{
        font-size: 16px;
        font-weight: 600;
        color: var(--primary-color1);
        display: block;
        white-space: nowrap;
        transform: rotate(90deg);
        background: var(--white);
        padding: 5px 10px;
    }
}
.banner2-section{
    position: relative;
    padding-left: 130px;
    @include lg-device(){
        padding-left: 90px;
    }
    @include lg-down-device(){
        padding-left: 0px;
    }
    .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
      }

      .swiper-slide {
        background-size: cover;
        background-position: center;
        position: relative;
        
        img{
            transition: all 0.65s ease;
        }
        .banner-content{
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            width: 100%;
            max-width: 800px;
            padding: 60px 30px;
            @include xxl-down-device(){
                top: 50%;
            }
            p{
                font-size: 20px;
                font-weight: 400;
                font-family: var(--font-exo);
                color: var(--primary-color1);
                margin-bottom: 10px;
                line-height: 1;
            }
            span{
                width: 27.81px;
                height: 5px;
                background: #CA9457;
                border-radius: 24px;
                display: inline-block;
                position: relative;
                margin-bottom: 18px;
                &::before{
                    content: '';
                    position: absolute;
                    left: 31px;
                    top: 2px;
                    width: 54.5px;
                    height: 1px;
                    background: var(--primary-color2);
                }
                &::after{
                    content: '';
                    position: absolute;
                    right: 31px;
                    top: 2px;
                    width: 54.5px;
                    height: 1px;
                    background: var(--primary-color2);
                }
            }
            h2{
                font-size: 4.1rem;
                color: var(--white);
                font-weight: 700;
                line-height: 1.35;
                margin-bottom: 50px;
                @include sm-down-device(){
                    font-size: 3.2rem;
                }
            }
        }
      }

      .mySwiper2 {
        height: 80vh;
        width: 100%;
        .swiper-slide{
            position: relative;
            z-index: 1;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(#292B31, 0.7);
            }
        }
        .swiper-button-next{
            display: none;
            visibility: hidden;
        }
        .swiper-button-prev{
            display: none;
            visibility: hidden;
        }
      }

      .mySwiper {
        box-sizing: border-box;
        padding: 10px 0;
        .swiper-wrapper{
            .swiper-slide {
                img{
                    height: 65px;
                    width: 75px;
                    border-radius: 0px 50px 50px 0px;
                    filter: sepia(1) hue-rotate(270deg);
                    @include lg-device(){
                        width: 70px;
                        height: 60px;
                    }
                }
            }
        }
        &.smalll-image{
            background-color: var(--dark);
            width: 130px;
            margin: unset;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            @include lg-device(){
                width: 90px;
            }
            @include lg-down-device(){
                display: none;
            }
        }
      }

      .mySwiper .swiper-slide {
        width: 90px !important;
        height: 90px !important;
        opacity: 0.4;
      }

      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
        img{
            width: 100px !important;
            filter: unset !important;
            @include lg-device(){
                width: 80px !important;
            }
        }
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .banner2-arrows{
        position: absolute;
        left: 40px;
        bottom: 30px;
        z-index: 9;
        @include lg-device(){
            left: 25px;
        }
        @include lg-down-device(){
            left: 15px;
        }
      }
}
.banner-section3{
    position: relative;
    min-height: 90vh;
    @include flex(flex-start);
    align-items: center;
    background-color: #1B1B22;
    z-index: 1;
    &.sibling2{
        background: var(--white);
    }
    // &::after{
    //     content: '';
    //     position:absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: rgba(#202020,0.7);
    //     z-index: -1;
    // }
    .banner3-vector{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4%;
        @include md-down-device(){
            display: none;
            visibility: hidden;
        }
    }
    .banner3-bg{
        width: 100%;
        max-width: 1545px;
        height: 100%;   
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('/images/bg/banner31.png');
        @include img-adjust();
        z-index: -1;
        @media (min-width:1400px) and (max-width: 1830px){
            max-width: 1350px;
            width: 100%;
        }
        @include xl-device(){
            max-width: 1050px;
        }
         &::after{
            content: '';
            position:absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(#202020,0.7);
            z-index: 999;
        }
    }
    .bann31-single{
        padding: 40px 10% 40px 15%;
        @include xxl-device(){
            padding-left: 5%;
        }
        @include xl-device(){
            padding-left: 7%;
        }
        @include xl-down-device(){
            flex-wrap: wrap;
        }
        @include lg-device(){
            padding: 70px 100px;
        }
        @include lg-down-device(){
            padding: 70px 30px;
        }
        .content{
            width: 100%;
            max-width: 890px;
            padding-right: 0px;
            @include xl-down-device(){
                text-align: center;
                max-width: 100%;
                // width: 100%;
            }
            > span{
                @include font(1.375rem,400,var(--primary-color3));
                display: inline-block;
                position: relative;
                margin-bottom: 10px;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 100%;
                    background: linear-gradient(90deg, #D7B679 0%, transparent 95.32%);
                }
            }
            h2{
                @include font(4.7rem,600,var(--white));
                margin-bottom: 50px;
                span{
                    @include font(4.7rem,600,var(--primary-color3));
                }
                @include xl-device(){
                    @include font(4rem,600,var(--white));
                }
                @include md-device(){
                    @include font(4.2rem,600,var(--white));
                }
                @include sm-device(){
                    @include font(4rem,600,var(--white));
                }
                @include sm-down-device(){
                    @include font(3.8rem,600,var(--white));
                }
            }
        }
        .banner3-lawyer-img{
            position: relative;
            z-index: 1;
            width: 100%;
            max-width: 465px;
            margin-left: 20px;
            @include xl-down-device(){
                margin-top: 70px;
                margin-left: auto;
                margin-right: auto;
            }
            @include sm-down-device(){
                max-width: 300px;
            }
            &::before{
                content: url('/public/images/bg/banner3-lawyer-vctr.svg');
                position: absolute;
                right: -25px;
                top: -28px;
                z-index: -1;
                @include sm-down-device(){
                    display: none;
                    visibility: hidden;
                }
            }
            img{
                border-radius: 15px;
            }
        }
        .video-btn{
            color: var(--white);
        }
        // video-popup
        .video-play {
            text-align: center;
            z-index: 9;
            .video-icon {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                line-height: 40px;
                display: inline-block;
                text-align: center;
                position: relative;
                border: 1px solid var(--primary-color3);
                font-size: 36px;
                color: var(--white);
                background: var(--primary-color3);
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    background: rgba(215, 182, 121,0.5);
                    -webkit-animation: ripple-1 2s infinite ease-in-out;
                    animation: ripple-1 2s infinite ease-in-out;
                    z-index: -1;
                }
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    background: rgba(215, 182, 121,0.3);
                    z-index: -1;
                    -webkit-animation: ripple-2 2s infinite ease-in-out;
                    animation: ripple-2 2s infinite ease-in-out;
                    -webkit-animation-delay: .5s;
                    animation-delay: .5s;
                }
            }
        }
        @keyframes ripple-1 {
            0% {
                -webkit-transform: scale(1);
                transform: scale(1);
                opacity: 1
            }
            100% {
                -webkit-transform: scale(1.7);
                transform: scale(1.7);
                opacity: 0
            }
        }

        @keyframes ripple-2 {
            0% {
                -webkit-transform: scale(1);
                transform: scale(1);
                opacity: 1
            }
            100% {
                -webkit-transform: scale(1.9);
                transform: scale(1.9);
                opacity: 0
            }
        }
    }
    .banner3-arrows{
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 99;
        @include lg-device(){
            left: 25px;
        }
        @include lg-down-device(){
            left: 15px;
        }
      }
}
.swiper-slide-active span {
    animation: fadeInUp 1.7s;
}
.swiper-slide-active h1 {
    animation: fadeInDown 1.7s;
}
.swiper-slide-active h2 {
    animation: fadeInDown 1.7s;
}
.swiper-slide-active p {
    animation: fadeInUp 1.7s;
}

.swiper-slide-active .eg-btn {
    animation: fadeInUp 1.7s;
}

/*=======================================
   12. Intro-section
=======================================*/
.intro-section{
    background: #F7F7F7;
}
.border-unset{
    &.intro-single{
        &::before{
            content: unset;
        }
    }
}
.intro-single{
    background: #F7F7F7;
    padding: 60px 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include md-down-device(){
        padding: 30px 25px;
    }
    &:hover{
        span.s-number{
            -webkit-text-fill-color: rgba(#fff,0.1);
        }
        &::after{
            transform: scaleX(1);
            opacity: 1;
        }
        .intro-wrap{
            text-align: center;
            h3{
                color: var(--white);
            }
        }
    }
    &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: var(--dark);
        transform: scalex(0);
        transform-origin: left;
        transition: all 0.65s ease;
        opacity: 0;
    }
    &::before{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 110px;
        z-index: -1;
        background-color: rgba(#202020, 0.1);
        @include xxl-down-device(){
            content: unset;
        }
    }
    span.s-number{
        font-size: 3rem;
        font-weight: 700;
        font-family: var(--font-exo);
        -webkit-text-stroke: 1px rgba(#202020,0.1);
        -webkit-text-fill-color: transparent;
        position: absolute;
        top: 0px;
        left: 20px;
    }
    .intro-wrap{
        text-align: center;
        img{
            max-width: 70px;
            height: auto;
            margin: auto;
            margin-bottom: 30px;
            @include md-down-device(){
                margin-bottom: 20px;
            }
        }
        h3{
            font-size: 35px;
            font-weight: 700;
            font-family: var(--font-lora);
            margin-bottom: 3px;
            transition: all 0.45s ease-in-out;
        }
        h5{
            font-size: 18px;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--text-light);
        }
    }
    &.sibling2{
        background: var(--dark2);
        padding: 45px 30px;
        position: relative;
        &:hover{
            &::after{
                opacity: 1;
                transform: scaleX(1);
            }
        }
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: rgba(#ffffff, 0.12);
            transform: scalex(0);
            transform-origin: left;
            transition: all 0.65s ease;
            opacity: 0;
        }
        &::before{
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 110px;
            z-index: -1;
            background-color: rgba(#fff, 0.1);
            @include xxl-down-device(){
                content: unset;
            }
        }
        .intro-wrap{
            text-align: center;
            img{
                max-width: 70px;
                height: auto;
                margin: auto;
                margin-bottom: 30px;
                @include md-down-device(){
                    margin-bottom: 20px;
                }
            }
            h3{
                font-size: 35px;
                font-weight: 700;
                font-family: var(--font-lora);
                margin-bottom: 3px;
                transition: all 0.45s ease-in-out;
                color: var(--white);
            }
            h5{
                font-size: 18px;
                font-weight: 500;
                font-family: var(--font-exo);
                color: var(--text-light);
            }
        }
    }
}
.border-top-left-bottom{
    border-radius: 60px 0px 0px 60px;
    @include lg-down-device(){
        border-radius: 0px;
    }
}
.border-top-right-bottom{
    border-radius: 0px 60px 60px 0px;
    @include lg-down-device(){
        border-radius: 0px;
    }
}
.intro-section2{
    padding-left: 130px;
    margin-top: -80px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @include xl-device(){
        margin-top: unset;
        padding-left: 130px;
    }
    @include lg-device(){
        margin-top: unset;
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 100px;
    }
    @include md-device(){
        margin-top: unset;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 90px;
    }
    @include md-down-device(){
        margin-top: unset;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 90px;
    }
}
.intro-singe2{
    background: #1B1B1B;
    padding: 30px;
    position: relative;
    @include flex(flex-start);
    gap: 25px;
    transition: all 0.5s ease;
    transform-style: preserve-3d;
    &:hover{
        transform: perspective(300px) rotateX(5deg);
        box-shadow: 3px 34px 16px -18px #e3e3e3d4;
    }
    @include lg-device(){
        flex-wrap: wrap;
    }
    @include md-down-device(){
        flex-wrap: wrap;
    }
    span.s-number{
        font-size: 6.25rem;
        font-weight: 700;
        font-family: var(--font-lora);
        color: rgba(#fff, 0.02);
        position: absolute;
        top: -8px;
        right: 5px;
        line-height: 1;
    }
    .text{
        h4{
            font-size: 1.688rem;
            font-weight: 700;
            color: var(--white);
            margin-bottom: 15px;
        }
        p{
            margin-bottom: 0px;
            font-size: 15px;
            font-weight: 300;
            color: var(--white);
            font-family: var(--font-exo);
            line-height: 1.8;
        }
    }
    &.sibling2{
        background: #252525;
    }
}

/*=======================================
   13. About-section
=======================================*/
.about-section{
    position: relative;
    overflow: hidden;
    z-index: 1;
    .section-bg1{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        @include md-down-device(){
            display: none;
            visibility: hidden;
        }
    }
}
.about-content1{
    margin-top: 22px;
    h3{
        font-size: 1.875rem;
        font-weight: 700;
        color: var(--text-color1);
        line-height: 1.35;
    }
}
.lawyer-word{
    border: 1px solid rgba(#202020,.07);
    border-radius: 0px;
    padding: 20px;
    margin-top: 32px;
    background: var(--white);
    p{
        font-size: 16px;
        line-height: 1.8;
        color: var(--text-color2);
        font-weight: 400;
    }
    h6{
        font-size: 16px;
        font-weight: 400;
        color: var(--primary-color1);
    }
    img{
        margin-bottom: 16px;
    }
}
.about1-img{
    position: relative;
    border-radius: 240px;
    margin-left: 40px;
    margin-bottom: 15px;
    &::before{
        content: '';
        position: absolute;
        left: -30px;
        top: 15px;
        background: rgba(#DEA057,0.2);
        border-radius: 240px;
        width: 100%;
        max-width: 490px;
        height: 100%;
        // z-index: -1;
    }
    img{
        border-radius: 240px;
    }
    @include lg-down-device(){
        margin-top: 70px;
    }
}
.experience-tag{
    width: 100%;
    max-width: 170px;
    text-align: center;
    background: var(--text-color1);
    border-radius: 50%;
    height: 100%;
    max-height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -25px;
    right: -25px;
    @include sm-down-device(){
        top: 185px;
        right: 10px;
    }
    &::before{
        content: '';
        position: absolute;
        left: -5px;
        top: -5px;
        background: var(--white);
        border: 2px solid var(--text-color1);
        width: 106%;
        max-width: 190px;
        height: 106%;
        max-height: 190px;
        border-radius: 50%;
        z-index: -1;
    }
    &::after{
        content: '';
        background: #FFFFFF;
        box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
        width: 60px;
        height: 100%;
        max-height: 60px;
        background-image: url('/public/images/icons/home-icon.svg');
        background-size:auto;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-position: center;
        position: absolute;
        top: -10px;
        right: -5px;
    }
    h2{
        font-size: 3.125rem;
        color: var(--primary-color1);
        font-style: italic;
    }
    span{
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        font-family: var(--font-exo);
        font-style: normal;
    }
    p{
        font-size: 13px;
        font-weight: 400;
        color: var(--white);
        font-family: var(--font-exo);
        margin-bottom: 0;
    }
}

.about3-content{
    p.para{
        color: var(--dark-mode-light);
    }
    .about3-author{
        border: 1px solid #D7B679;
        padding: 5px;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        position: relative;
        img.author-image{
            border-radius: 50%;
            position: absolute;
            left: 50%;top: 50%;
            transform: translate(-50%,-50%);
        }
    }
    &.sibling2{
        p.para{
            color: var(--text-color2);
        }
    }
}
.about3-list{
    @include list(none,30px 0 55px 0,0);
    li{
        @include font(16px,500,var(--white));
        padding-left: 22px;
        margin-bottom: 25px;
        position: relative;
        display: inline-block;
        &::before{
            content: url('/public/images/icons/check.svg');
            position: absolute;
            left: 0;
            top: 1px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
    }
    &.sibling2{
        li{
            @include font(16px,500,var(--dark3));
        }
    }
}
.about3-image-area{
    position: relative;
    background: var(--dark3);
    padding-bottom: 75px;
    border-radius: 30px 30px 500px 500px;
    width: 100%;
    max-width: 545px;
    @include sm-down-device(){
        display: none;
        visibility: hidden;
    }
    span.years{
        width: 75px;
        height: 75px;
        border-radius: 50%;
        border: 1px solid var(--primary-color3);
        @include font(3.3rem,700,var(--primary-color3));
        font-family: var(--font-lora);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        @include flex(center);
        align-items: center;
    }

    .abt-img-text1{
        position: absolute;
        bottom: 38px;
        left: 9px;
    }
    .abt-img-text2{
        position: absolute;
        bottom: 6px;
        right: 70px;
    }
    .about3-image{
        border-radius: 30px 30px 500px 500px;
        max-width: 100%;
    }
}

/*=======================================
   14. Practice-area-section
=======================================*/
.practice-area-section2{
    .swiper-container{
        overflow: hidden;
        .swiper-wrapper{
            grid-gap: 0px;
        }
    }
    padding-left: 5rem;
    @include lg-down-device(){
        padding-left: unset;
    }
    .swiper-wrapper {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 2fr;
        grid-template-rows: auto auto; // here is the amount of slidesPerColumn you want
        grid-auto-flow: column;
  
        & > .swiper-slide {
          height: fit-content;
        }
      }
    .swiper-container {
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
      .swiper-slide {
        font-size: 18px;
        background: #fff;
        // height: 200px;
  
        /* Center slide text vertically */
      
      }
}
.practice-area-section3{
    .swiper{
        margin: -15px;
        padding: 15px;
    }
}
.practice-single{
    &:hover{
        .header{
            background: var(--primary-color1);
        }
        .body{
            padding: 30px;
            background: var(--dark-bg);
            p{
                color: var(--white);
            }
        }
    }
    .header{
        padding: 30px;
        background: var(--primary-light);
        border-radius: 10px 10px 0px 0px;
        transition: all .5s ease-out 0s;
        h4{
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--text-color1);
            margin-bottom: 0px;
            transition: all .5s ease-out 0s;
        }
        .icon-area{
            @include flex(space-between);
            margin-bottom: 25px;
            svg{
                fill: var(--dark-bg);
                transition: all .5s ease-out 0s;
            }
        }
    }
    .body{
        padding: 30px;
        background: var(--dark-light);
        border-radius: 0px 0px 10px 10px;
        transition: all .5s ease-out 0s;

        p{
            font-size: 16px;
            font-weight: 400;
            color: var(--text-color2);
            line-height: 1.8;
            transition: all .5s ease-out 0s;
        }
        .details-btn{
            font-size: 13px;
            font-weight: 600;
            color: var(--primary-color1);
            font-family: var(--font-exo);
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-bottom: 5px;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 40px;
                height: 3px;
                border-radius: 4px;
                background: var(--primary-color1);
                transition: all 0.4s ease;
            }
            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 1px;
                width: 73px;
                height: 1px;
                border-radius: 4px;
                background: var(--dark-bg);
                z-index: -1;
            }
            &:hover{
                &::before{
                    width: 73px;
                }
            }
        }
    }
    &.sibling1{
        .header{
            background: var(--dark-light);
            transition: all .5s ease-out 0s;
        }
        .body{
            background: var(--primary-light);
            transition: all .5s ease-out 0s;
        }
        &:hover{
            .header{
                background: var(--dark-bg);
                svg{
                    fill: var(--white);
                }
                a.eg-btn{
                    color: var(--white);
                }
                h4{
                    color: var(--white);
                }
            }
            .body{
                background: var(--primary-color1);
                .details-btn{
                    color: var(--white);
                    &::before{
                        background: var(--white);
                    }
                }
            }
        }
    }
}
.practice-single2{
    position: relative;
    @include flex(flex-start);
    gap: 15px;
    border: 1px solid   #eee;
    margin-top: 20px;
    .sl-number{
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 2.25rem;
        font-weight: 700;
        color: rgba(#292B31,0.1);
        font-family: var(--font-lora);
    }
    .image{
        img{
                display: none;
                visibility: hidden;
            
        }
    }
    .content{
        padding: 20px 15px;
        .icon{
            margin-bottom: 15px;
        }
        .text{
            h5{
                font-size: 22px;
                font-weight: 700;
                color: var(--text-color);
            }
            p{
                font-size: 15px;
                font-weight: 400;
                // margin-bottom: 0;
            }
            .details-btn{
                font-size: 13px;
                font-weight: 600;
                color: var(--primary-color1);
                font-family: var(--font-exo);
                position: relative;
                z-index: 1;
                display: inline-block;
                padding-bottom: 5px;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 40px;
                    height: 3px;
                    border-radius: 4px;
                    background: var(--primary-color1);
                    transition: all 0.4s ease;
                }
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 1px;
                    width: 73px;
                    height: 1px;
                    border-radius: 4px;
                    background: var(--dark-bg);
                    z-index: -1;
                }
                &:hover{
                    &::before{
                        width: 73px;
                    }
                }
            }
        }
    }
}
.practice-single3{
    border-radius: 10px;
    background: var(--dark3);
    padding: 15px;
    &:hover{
        .practice-image{
            &::after{
                clip-path: polygon(0% 100%, 0 100%, 100% 100%);
            }
        }
    }
    .practice-image{
        position: relative;
        z-index: 9;
        &::after{
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 40px;
            background: var(--dark3);
            clip-path: polygon(0% 24%, 0 100%, 100% 100%);
            transition: all 0.5s ease;
            z-index: 1;
        }
        > img{
            border-radius: 8px;
            width: 100%;
        }
        .practice-icon{
            position: absolute;
            left: -2px;
            bottom: -2px;
            @include circle(80px,80px,80px,center,50%);
            background-color: var(--dark-mode-bg);
            z-index: 9;
        }
    }
    .practice-content{
        padding: 15px;
        padding-top: 25px;
        h4{
            color: var(--white);
            @include font(1.563rem,700, var(--white));
            margin-bottom: 15px;
        }
        p{
            @include font(1rem, 400, Var(--text-light2));
        }
        .details-btn{
            font-size: 16px;
            font-weight: 600;
            color: var(--primary-color3);
            font-family: var(--font-exo);
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-bottom: 5px;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 40px;
                height: 3px;
                border-radius: 4px;
                background: var(--primary-color3);
                transition: all 0.4s ease;
            }
            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 1px;
                width: 73px;
                height: 1px;
                border-radius: 4px;
                background: var(--white);
                z-index: -1;
            }
            &:hover{
                &::before{
                    width: 73px;
                }
            }
        }
    }
    &.sibling2{
        background: var(--white);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
        &:hover{
            .practice-image{
                &::after{
                    clip-path: polygon(0% 100%, 0 100%, 100% 100%);
                }
            }
        }
        .practice-image{
            position: relative;
            z-index: 9;
            &::after{
                background: var(--white);
                z-index: 1;
            }
            > img{
                border-radius: 8px;
                width: 100%;
            }
            .practice-icon{
                background-color: var(--dark3);
            }
        }
        .practice-content{
            h4{
                @include font(1.563rem,700, var(--dark3));
            }
            p{
                @include font(1rem, 400, Var(--text-color2));
            }
            .details-btn{
                font-size: 16px;
                font-weight: 600;
                color: var(--primary-color3);
                font-family: var(--font-exo);
                position: relative;
                z-index: 1;
                display: inline-block;
                padding-bottom: 5px;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 40px;
                    height: 3px;
                    border-radius: 4px;
                    background: var(--primary-color3);
                    transition: all 0.4s ease;
                }
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 1px;
                    width: 73px;
                    height: 1px;
                    border-radius: 4px;
                    background: var(--white);
                    z-index: -1;
                }
                &:hover{
                    &::before{
                        width: 73px;
                    }
                }
            }
        }  
    }
}
/*=======================================
15. Info-section
=======================================*/
.info-section{
    background: linear-gradient(rgba(#292B31,0.9),rgba(#292B31,0.9)),url('/public/images/bg/info-bg.png');
    @include img-adjust();
    background-attachment: fixed;
    padding: 90px 10% 90px 12%;
    @include xl-device(){
        padding: 90px 5% 90px 5%;
    }
    @include lg-device(){
        padding: 90px 5% 90px 5%;
    }
    @include md-device(){
        padding: 90px 5% 90px 5%;
    }
    @include md-down-device(){
        padding: 90px 2% 90px 2%;
    }

.info-content{
    padding-left: 30px;
    @include lg-down-device(){
        padding-left: 0px;
    }
    h2{
        font-size: 2.8125rem;
        font-weight: 700;
        color: var(--white);
        margin-bottom: 30px;
        line-height: 1.4;
        @include md-down-device(){
            font-size: 2.2rem;
        }
    }
    p{
        font-size: 16px;
        font-weight: 400;
        font-family: var(--font-exo);
        line-height: 1.8;
        color: var(--white);
    }
}
.info-list{
    list-style: none;
    margin: 40px 0 0 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    gap: 80px;
    justify-content: start;
    @include md-device(){
        gap: 50px;
    }
    @include md-down-device(){
        gap: 30px;
    }
    li{
        h4{
            font-size: 1.5rem;
            color: var(--primary-color1);
        }
        p{
            font-size: 15px;
            font-weight: 500;
            color: var(--font-exo);
            color: var(--white);
        }
    }
}
.info-video{
    position: relative;
    min-height: 100%;
    @include md-down-device(){
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.video-play {
    text-align: center;
    z-index: 9;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    @include md-down-device(){
        position: unset;
    }
    .video-icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 52px;
        display: inline-block;
        text-align: center;
        position: relative;
        font-size: 36px;
        color: var(--white);
        // background: rgba(#fff,0.1);
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            background: rgba(#fff,0.1);
            -webkit-animation: ripple-1 2s infinite ease-in-out;
            animation: ripple-1 2s infinite ease-in-out;
            z-index: -1;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            background: rgba(#fff,0.1);
            z-index: -1;
            -webkit-animation: ripple-2 2s infinite ease-in-out;
            animation: ripple-2 2s infinite ease-in-out;
            -webkit-animation-delay: .5s;
            animation-delay: .5s;
        }
    }
}
@keyframes ripple-1 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
        opacity: 0
    }
}

@keyframes ripple-2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.9);
        transform: scale(1.9);
        opacity: 0
    }
}
}

/*=======================================
 16. Casestudy Section
=======================================*/
.casestudy-section{
    position: relative;
    overflow: hidden;
    .section-bg1{
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .section-bg2{
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
.casestudy-gallery{
    .button-group{
        border-bottom: 2px solid #eee;
        padding-bottom: 0px;
        padding-top: 52px;
        // gap: 40px;
        button{
            font-size: 17px;
            font-weight: 500;
            color: var(--text-color1);
            font-family: var(--font-exo);
            background: transparent;
            text-transform: capitalize;
            position: relative;
            padding-bottom: 17px;
            transition: all 0.5s ease;
            border-bottom: 2px solid transparent;
            &:focus{
                color: var(--primary-color3);
                border-bottom: 2px solid var(--primary-color3);
            }
            &.active{
                color: var(--primary-color3);
                border-bottom: 2px solid var(--primary-color3);
            }
        }
    }
}
.casestudy-single{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    min-height: 450px;
    padding: 30px;
    display: flex;
    align-items: flex-end;
    background: linear-gradient(179.97deg, rgba(32, 32, 32, 0) 20.42%, #202020 99.97%);
    margin-left: auto;
    margin-right: auto;
    @include xl-device(){
        min-height: 400px;
    }
    @include lg-device(){
        min-height: 370px;
    }
    @include md-device(){
        min-height: 360px;
    }
    @include sm-device(){
        min-height: 400px;
    }
    @include sm-down-device(){
        min-height: 360px;
    }

    .casestudy1{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.65s ease-out 0s;
    }
    .text{
        span{
            font-size: 18px;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--primary-color1);
            display: inline-block;
            position: relative;
            padding-bottom: 3px;
            margin-bottom: 8px;
            text-transform: capitalize;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                border-radius: 10px;
                background: var(--primary-color1);
            }
        }
        h3{
            font-size: 1.875rem;
            font-weight: 700;
            color: var(--white);
            margin-bottom: 0px;
        }
    }
    .read-more{
        position: absolute;
        overflow: hidden;
        right: 30px;
        top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border: 1px solid var(--white);
        border-radius: 100px;
        transition: all 0.55s ease-in-out;
        width: 40px;
        height: 40px;
        span.btn-text{
            font-size: 15px;
            font-weight: 600;
            color: var(--white);
            font-family: var(--font-exo);
            transition: all 0.55s ease-in-out;
            opacity: 0;
            width: 0;
            white-space: nowrap;
            text-transform: capitalize;
        }
        span.btn-arrow{
            transform: rotate(-45deg);
            transition: all 0.65s ease-in-out;
            i{
                font-size: 18px;
                font-weight: 600;
                color: var(--white);
            } 
        }
        &:hover{
            width: 150px;
            padding: 0px 20px;
            span.btn-arrow{
                transform: rotate(0deg);
            }
            span.btn-text{
                opacity: 1;
                width: 100%;
            }
        }
    }
    &:hover{
        .casestudy1{
            transform: scale(1.2) rotate(3deg);
        }
    }
}
.casestudy-single2{
    position: relative;
    overflow: hidden;
    border-radius: 0px;
    min-height: 650px;
    display: flex;
    align-items: flex-end;
    background: linear-gradient(179.97deg, rgba(32, 32, 32, 0) 20.42%, #202020 99.97%);

    @include xxl-device(){
        min-height: 550px;
    }
    @include xl-device(){
        min-height: 520px;
    }
    @include lg-device(){
        min-height: 480px;
    }
    @include md-device(){
        min-height: 430px;
    }
    @include md-down-device(){
        min-height: 390px;
    }
    .casestudy1{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.65s ease-in-out;
    }
    .text{
        margin-bottom: 40px;
        padding-left: 30px;
        transition: all 0.35s ease-in-out;
        transform: scaleY(1);
        span{
            font-size: 18px;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--primary-color1);
            display: inline-block;
            position: relative;
            padding-bottom: 3px;
            margin-bottom: 8px;
            text-transform: capitalize;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                border-radius: 10px;
                background: var(--primary-color1);
            }
        }
        h3{
            font-size: 1.875rem;
            font-weight: 700;
            color: var(--white);
            margin-bottom: 0px;
        }
    }
    .content-wrapper{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        transform: translateY(100%);
        // transform-origin: top;
        transition: all 0.6s ease;
    }
    .content{
        transition: all 0.65s ease-in-out;
        background: var(--white);
        width: 100%;
        padding: 25px 30px;
        span{
            font-size: 18px;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--primary-color1);
            display: inline-block;
            position: relative;
            padding-bottom: 3px;
            margin-bottom: 8px;
            text-transform: capitalize;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                border-radius: 10px;
                background: var(--primary-color1);
            }
        }
        h3{
            font-size: 1.875rem;
            font-weight: 700;
            color: var(--text-color1);
            margin-bottom: 15px;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            color: var(--text-color2);
            font-family: var(--font-exo);
        }
        .details-btn{
            font-size: 13px;
            font-weight: 600;
            color: var(--primary-color1);
            font-family: var(--font-exo);
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-bottom: 5px;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 60%;
                height: 3px;
                border-radius: 4px;
                background: var(--primary-color1);
                transition: all 0.4s ease;
            }
            &:hover{
                &::before{
                    width: 100%;
                }
            }
        }
    }
    
    &:hover{
        .content-wrapper{
            opacity: 1;
            transform: translateY(0%);

        }
        .text{
            opacity: 0;
            transform: scaleY(1);
        }
    }
}
.casestudy-single3{
    overflow: hidden;
    &:hover{
        .image{
            .case-details-arrow{
                opacity: 1;
            }
        }
    }
    .image{
        position: relative;
        z-index: 1;
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(179.97deg, rgba(32, 32, 32, 0) 20.42%, #202020 99.97%);
        }
        .case-details-arrow{
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%,-50%) rotate(0deg);
            opacity: 0;
            transition: all 0.55s ease;
            z-index: 1;
            cursor: pointer;
            &:hover{
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .content{
        padding: 30px 30px;
        background: var(--dark3);
        span{
            @include font(18px,400,var(--primary-color3));
            display: inline-block;
            margin-bottom: 6px;
        }
        h4{
            margin-bottom: 0px;
            a{
                @include font(1.563rem,700,var(--white));
                transition: all 0.5s ease-out 0s;
            }
            &:hover{
                a{
                    color: var(--primary-color3);
                }
            }
        }
    }
    &.sibling2{
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        .content{
            background: var(--white);
            h4{
                margin-bottom: 0px;
                a{
                    @include font(1.563rem,700,var(--dark3));
                    transition: all 0.5s ease-out 0s;
                }
                &:hover{
                    a{
                        color: var(--primary-color3);
                    }
                }
            }
        }
    }
}
.case-details-single{
    > img{
        border-radius: 10px;
        margin-bottom: 25px;
    }
    h2{
        font-size: 2.813rem;
        font-weight: 700;
        font-family: var(--font-lora);
        color: var(--text-color);
        margin-bottom: 22px;
    }
   > h3{
        font-size: 2.188rem;
        font-weight: 700;
        font-family: var(--font-lora);
        color: var(--text-color);
        margin-bottom: 14px;
    }
    .details-img-grp{
        padding: 10px 0px 35px 0px;
        img{
            border-radius: 5px;
        }
    }
    .process-list{
        list-style: none;
        padding: 15px 0 13px 0;
        margin: 0;
        li{
            margin-bottom: 27px;
            &:last-child{
                margin-bottom: 0;
            }
            .list-title{
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 20px;
                @include md-down-device(){
                    gap: 15px;
                }
                h3{
                    font-size: 1.875rem;
                    font-weight: 700;
                    font-family: var(--font-lora);
                    color: var(--text-color1);
                }
            }
            p.para{
                padding-left: 60px;
            }
        }
    }
}
.case-info-list{
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    list-style: none;
    margin: 0 0 40px 0;
    padding: 5px 0px;
    li{
        padding: 20px 30px;
        border-bottom: 1px solid rgba(#eee,0.5);
        &:hover{
            h5{
                padding-left: 30px;
            }
        }
        &:last-child{
            border-bottom: none;
        }
        span{
            font-size: 17px;
            font-weight: 500;
            color: var(--primary-color3);
            font-family: var(--font-exo);
            img{
                margin-right: 5px;
            }
        }
    h5{
        font-size: 20px;
        font-weight: 600;
        color: var(--text-color1);
        margin-bottom: 0;
        transition:  all 0.52s ease;
    }
    }
}
.casestudy-card{
    border-radius: 5px;
    overflow: hidden;
    .header{
        background: var(--primary-color3);
        padding: 20px 40px;
        h4{
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--white);
            margin-bottom: 0;
        }
    }
    .casestudy-list{
        list-style: none;
        background: var(--green);
        margin: 0 0 40px 0;
        padding: 30px 40px;
        li{
            @include flex(space-between);
            margin-bottom: 25px;
            &:last-child{
                margin-bottom: 0px;
            }
            span{
                font-size: 18px;
                font-weight: 500;
                font-family: var(--font-exo);
                color: var(--white);
                transition: all 0.52s ease;
            }
            &:hover{
                >span{
                    padding-left: 20px;
                }
            }
        }
    }
}
.lawyer-intro-card{
    position: relative;
    background: linear-gradient(152.97deg, rgba(202, 148, 87, 0.12) 0%, rgba(202, 148, 87, 0.09) 100%);
    text-align: center;
    padding: 30px;
    img{
        border-radius: 50%;
        margin-bottom: 18px;
    }
    span.badge{
        font-size: 15px;
        font-weight: 600;
        color: var(--white);
        font-family: var(--font-exo);
        padding: 8px 20px;
        border-radius: 5px;
        position: absolute;
        top: 15px;
        right: 15px;
        background: var(--primary-color3);
    }
    .designation{
        h4{
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--text-color1);
            margin-bottom: 5px;
        }
        p{
            font-size: 1.125rem;
            font-weight: 500;
            color: var(--primary-color3);
            margin-bottom: 0;
        }
    }
}

/*=======================================
 17. Attorney Section
=======================================*/
.attorney-single{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    min-height: 375px;
    padding: 30px;
    width: 100%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-end;
    background: linear-gradient(179.97deg, rgba(32, 32, 32, 0) 20.42%, #202020 99.97%);

    @include md-device(){
        min-height: 330px;
    }

    .casestudy1{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.65s ease-in-out;
        border-radius: 10px;
    }
    .content{
        text-align: center;
        width: 100%;
        margin-bottom: -75px;
        transition: all 0.65s ease-in-out;
        h4{
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--white);
            position: relative;
            padding-bottom: 8px;
            &::after{
                content: '';
                width: 60px;
                height: 2px;
                border-radius: 5px;
                background: var(--primary-color1);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
            }
        }
        p{
            font-size: 13px;
            font-weight: 500;
            color: var(--white);
            font-family: var(--font-exo);
        }
        .social-list{
            margin: 0;
            padding: 10px;
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: var(--primary-color1);
            width: 100%;
            border-radius: 5px 5px 0px 0px;
            li{
                a{
                    .bx{
                        width: 22px;
                        height: 22px;
                        line-height: 22px;
                        background-color: var(--dark);
                        border-radius: 0px;
                        color: var(--white);
                        border-radius: 5px;
                        font-size: 14px;
                        transition: all 0.35s ease;
                    }
                }
                &:hover{
                    a{
                        .bx{
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    &:hover{
        .content{
            margin-bottom: -30px;
        }
    }
    &.sibling2{
        .content{
            text-align: left;
            margin-bottom: 0px;
            h4{
                position: relative;
                &::after{
                    content: '';
                    width: 60px;
                    height: 2px;
                    border-radius: 5px;
                    background: var(--primary-color1);
                    position: absolute;
                    left: 30px;
                    bottom: 0;
                }
            }
            p{
                margin-bottom: 0px;
            }
        }
        &:hover{
            .social-list2{
                margin-right: 0px;
            }    
        }
        .social-list2{
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            max-width: 42px;
            margin: 0;
            padding: 15px 10px;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--green);
            border-radius: 5px 0px 0px 5px;
            // border-radius: 30px 0px 0px 30px;
            margin-right: -100%;
            transition: all 0.62s ease-in-out;
            li{
                a{
                    .bx{
                        width: 22px;
                        height: 22px;
                        line-height: 22px;
                        background-color: rgba(#fff, 0.2);
                        border-radius: 0px;
                        color: var(--white);
                        border-radius: 5px;
                        font-size: 14px;
                        transition: all 0.35s ease;
                        text-align: center;
                    }
                }
                &:hover{
                    a{
                        .bx{
                            background: var(--primary-color2);
                        }
                    }
                }
            }
        }
    }
    &.sibling3{
        .content{
            text-align: center;
            margin-bottom: 0px;
            h4{
                position: relative;
                &::after{
                    content: '';
                    width: 60px;
                    height: 2px;
                    border-radius: 5px;
                    background: var(--primary-color1);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                }
            }
            p{
                margin-bottom: 0px;
            }
        }
        &:hover{
            .social-list2{
                transform: translateX(0);
            }    
        }
        .social-list2{
            position: absolute;
            left: 0px;
            top: 0;
            bottom: 0;
            padding: 15px 10px;
            height: 100%;
            width: 100%;
            max-width: 42px;
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--dark-mode-bg);
            border-radius: 0px 0px 0px 0px;
            transition: all 0.62s ease-in-out;
            transform: translateX(-100%);
            li{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 10px;
                    bottom: -12px;
                    width: 3.2px;
                    height: 3.2px;
                    border-radius: 50%;
                    background: rgba(#fff,0.3);
                }
                a{
                    .bx{
                        width: 22px;
                        height: 22px;
                        line-height: 22px;
                        border-radius: 0px;
                        color: var(--white);
                        border-radius: 5px;
                        font-size: 14px;
                        transition: all 0.35s ease;
                        text-align: center;
                    }
                }
                &:hover{
                    a{
                        .bx{
                            color: var(--primary-color2);
                        }
                    }
                }
            }
        }
    }
    &.sibling4{
        .content{
            text-align: center;
            margin-bottom: 0px;
            h4{
                position: relative;
                &::after{
                    content: '';
                    width: 60px;
                    height: 2px;
                    border-radius: 5px;
                    background: var(--primary-color3);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                }
            }
            p{
                margin-bottom: 0px;
            }
        }
        &:hover{
            .social-list2{
                transform: translateX(0);
            }    
        }
        .social-list2{
            position: absolute;
            left: 0px;
            top: 0;
            bottom: 0;
            padding: 15px 10px;
            height: 100%;
            width: 100%;
            max-width: 42px;
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--white);
            border-radius: 0px 0px 0px 0px;
            transition: all 0.62s ease-in-out;
            transform: translateX(-100%);
            li{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 10px;
                    bottom: -12px;
                    width: 3.2px;
                    height: 3.2px;
                    border-radius: 50%;
                    background: rgba(#202020,0.3);
                }
                a{
                    .bx{
                        width: 22px;
                        height: 22px;
                        line-height: 22px;
                        border-radius: 0px;
                        color: #202020;
                        border-radius: 5px;
                        font-size: 14px;
                        transition: all 0.35s ease;
                        text-align: center;
                    }
                }
                &:hover{
                    a{
                        .bx{
                            color: var(--primary-color3);
                        }
                    }
                }
            }
        }
    }
}
.swiper-pagination.atrony2{
    height: 15px;
    position: absolute;
    bottom: 0px !important;
    .swiper-pagination-bullet{
        border: 1px solid #696969;
        width: 15px;
        height: 15px;
        position: relative;
        z-index: 1;
        background: transparent;
    }
    .swiper-pagination-bullet-active{
        position: relative;
        border: 1px solid var(--primary-color1);
        &::before{
            content: '';
            width: 9px;
            height: 9px;
            background: var(--primary-color1);
            position: absolute;
            left: 2px;
            top: 2px;
            border-radius: 50%;
            z-index: -1;
        }
    }
}
.swiper-pagination {
    height: 15px;
    position: absolute;
    bottom: 0px !important;
    .swiper-pagination-bullet{
        border: 1px solid #696969;
        width: 15px;
        height: 15px;
        position: relative;
        z-index: 1;
        background: transparent;
    }
    .swiper-pagination-bullet-active{
        position: relative;
        border: 1px solid var(--primary-color1);
        &::before{
            content: '';
            width: 9px;
            height: 9px;
            background: var(--primary-color1);
            position: absolute;
            left: 2px;
            top: 2px;
            border-radius: 50%;
            z-index: -1;
        }
    }
}

.contact-section{
    overflow: hidden;
}
.contact-form2{
    .nice-select {
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
        // backdrop-filter: blur(15px);
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-light);
        height: 52px;
        line-height: 52px;
        outline: none;
        position: relative;
        text-align: left !important;
        transition: all 0.2s ease-in-out;
        user-select: none;
        white-space: nowrap;
        width: 100%;
        border-radius: 5px;
        border: 1px solid rgba(202,148,87,.33);

        // border: 1px solid green;
        z-index: 1;
        margin-bottom: 40px;
        .option{
            min-height: 40px;
            line-height: 40px;
            font-size: 14px;
            &:hover{
                background: var(--primary-color2);
                color: var(--white);
            }
            &.selected{
                background: var(--primary-color2);
            }
        }
    
        .current{
            font-size: 14px;
            font-weight: 500;
            color: var(--text-light);
        }
    
        .list{
            border: unset;
            border: 1px solid rgba(41, 43, 49, 0.03);
            margin-top: 3px;
            z-index: 100;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
            min-width: 80px;
            background: var(--dark);
        }
    }
}
.contact-form3{
    .nice-select {
        background: var(--dark3);
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-light);
        height: 52px;
        line-height: 52px;
        outline: none;
        position: relative;
        text-align: left !important;
        transition: all 0.2s ease-in-out;
        user-select: none;
        white-space: nowrap;
        width: 100%;
        border-radius: 5px;
        border: 1px solid rgba(#fff,.15);

        // border: 1px solid green;
        z-index: 1;
        margin-bottom: 40px;
        .option{
            min-height: 40px;
            line-height: 40px;
            font-size: 14px;
            &:hover{
                background: var(--primary-color3);
                color: var(--white);
            }
            &.selected{
                background: var(--primary-color3);
            }
        }
    
        .current{
            font-size: 14px;
            font-weight: 500;
            color: var(--text-light);
        }
    
        .list{
            border: unset;
            border: 1px solid rgba(41, 43, 49, 0.03);
            margin-top: 3px;
            z-index: 100;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
            min-width: 80px;
            background: var(--dark);
        }
    }
}
.nice-select {
    background-color: var(--white);
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-light);
    height: 52px;
    line-height: 52px;
    outline: none;
    position: relative;
    text-align: left !important;
    transition: all 0.2s ease-in-out;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    border-radius: 0px;
    border: 1px solid rgba(41, 43, 49, 0.05);
    margin-bottom: 40px;
    .option{
        min-height: 40px;
        line-height: 40px;
        font-size: 14px;
        &:hover{
            background: var(--primary-color1);
            color: var(--text-color1);
        }
        &.selected{
            background: var(--primary-color1);
        }
    }

    .current{
        font-size: 14px;
        font-weight: 500;
        color: var(--text-light);
    }

    .list{
        border: unset;
        // box-shadow: 2px 2px 10px rgba(0,0,0, 0.1)
        border: 1px solid rgba(41, 43, 49, 0.03);
        margin-top: 3px;
        z-index: 100;
        border-radius: 0px;
        width: 100%;
        min-width: 80px;
    }
}
select .option.focus, .nice-select .option.selected.focus {
    background-color: var(--primary-light);
}

.css-1pahdxg-control{
    background-color: #373b43 !important;
    color: #000 !important;
}
.css-1s2u09g-control{
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
    border-color: 1px solid rgba(202, 148, 87, 0.4) !important;
}

.nice-select.open, .nice-select:focus {
    border-color: var(--primary-color1);
}
.css-319lph-ValueContainer{
    padding: 9px 2px !important;
}
.css-qc6sy-singleValue{
    margin-left: 15px !important;
    color: #fff !important;
}
.contact-form{
    .form-inner{
        .css-qc6sy-singleValue{
            margin-left: 15px !important;
            color: rgb(27, 25, 25) !important;
        }
        .css-1s2u09g-control{
            background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
            border-color: 1px solid rgba(202, 148, 87, 0.4) !important;
        }
        .css-1pahdxg-control{
            background-color: #fff !important;
            color: #000 !important;
        }
        margin-bottom: 40px;
        input{
            width: 100%;
            border: 1px solid rgba(41, 43, 49, 0.05);
            border-radius: 0px;
            padding: 14px 20px;
            transition: all 0.5s ease-in-out;
            font-size: 15px;
            font-family: var(--font-exo);
            &::placeholder{
                font-size: 14px;
                font-weight: 500;
                color: var(--text-light);
            }
            &:focus{
                border: 1px solid var(--primary-color1);
            }
        }
        textarea{
            @extend input;
        }
        &.glass-effect{
            input{
                width: 100%;
                border: 1px solid rgba(202, 148, 87, 0.4);
                border-radius: 5px;
                background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
                backdrop-filter: blur(15px);
                padding: 14px 20px;
                transition: all 0.5s ease-in-out;
                font-size: 15px;
                font-family: var(--font-exo);
                color: var(--white);
                &::placeholder{
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--text-light);
                }
                &:focus{
                    border: 1px solid var(--primary-color1);
                }
            }
            textarea{
                @extend input;
            }
        }
        &.dark-mode{
            input{
                width: 100%;
                border: 1px solid rgba(#fff, 0.15);
                border-radius: 5px;
                background-color: var(--dark3);
                padding: 14px 20px;
                transition: all 0.5s ease-in-out;
                font-size: 15px;
                font-family: var(--font-exo);
                color: var(--white);
                &::placeholder{
                    font-size: 13px;
                    font-weight: 500;
                    color: var(--text-light);
                    font-family: var(--font-exo);
                }
                &:focus{
                    border: 1px solid var(--primary-color3);
                }
            }
            textarea{
                @extend input;
            }
        }
        &.sibling2{
            input{
                border-radius: 5px;
            }
            textarea{
                border-radius: 5px;
            }
        }
    }
}
.contact-wrap{
    background-color: var(--light-color);
    padding: 45px 14%;
    @include lg-down-device(){
        padding: 45px 3%;
    }
}
.contact-text{
    h2{
        @include font(2.5rem,600,#fff);
        margin-bottom: 10px;
    }
    p{
        @include font(1rem,400,var(--dark-mode-light));
        margin-bottom: 45px;
    }
}
.address-list{
    margin: 0;
    padding: 0 0 40px 0px;
    list-style: none;
    width: 100%;
    max-width: 420px;
    @include lg-down-device(){
        padding: 40px 10px 40px 10px;
    }
    @include sm-down-device(){
       padding: 40px 0px 0px 0px;
    }
    li{
        margin-bottom: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 25px;
        &:last-child{
            margin-bottom: 0px;
        }
        &:hover{
            .icon{
                background: var(--dark);
            }
        }
        .icon{
            min-width: 70px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            border: 1px solid rgba(#202020,0.3);
            border-radius: 50%;
            transition: all 0.5s ease-out 0s;
            svg{
                fill: var(--primary-color1);
            }
        }
        .text{
            h4{
                font-size: 1.5rem;
                color: var(--primary-color1);
                font-weight: 700;
                margin-bottom: 10px;
                margin-top: -3px;
            }
            p{
                margin-bottom: 0;
                font-size: 15px;
                font-weight: 500;
                color: var(--text-color1);
                font-family: var(--font-exo);
            }
            a{
                font-size: 15px;
                font-weight: 500;
                color: var(--text-color1);
                font-family: var(--font-exo);  
            }
        }
    }
    &.sibling2{
        .icon{
            min-width: 70px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            border: 1px solid rgba(#fff,0.3);
            border-radius: 50%;
            transition: all 0.5s ease-out 0s;
            svg{
                fill: var(--primary-color2);
            }
        }
        .text{
            p{
                margin-bottom: 0;
                font-size: 15px;
                font-weight: 500;
                color: var(--white);
                font-family: var(--font-exo);
            }
            a{
                font-size: 15px;
                font-weight: 500;
                color: var(--white);
                font-family: var(--font-exo);  
            }
        }
    }
    &.sibling3{
        padding: 0px;
        li{
            &:hover{
                .icon{
                    background: rgba(#292B31,0.1);
                    svg{
                        fill: var(--text-color1);
                    }
                }
            }
        }
        .icon{
            min-width: 80px;
            height: 80px;
            line-height: 80px;
            background-color: rgba(#CA9457,0.1);
            border: unset;
            transition: all 0.5s ease-out 0s;
            svg{
                transition: inherit;
            }
        }
        .text{
            h4{
                font-size: 1.875rem;
                font-weight: 700;
                color: var(--text-color1);
            }
        }
    }
    &.sibling4{
        padding: 0;
        li{
            &:hover{
                .icon{
                    svg{
                        transform: scale(1.1);
                    }
                }
            }
        }
        
        .icon{
            min-width: 70px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            border: 1px solid rgba(#fff,0.3);
            border-radius: 50%;
            transition: all 0.5s ease-out 0s;
            svg{
                fill: var(--primary-color3);
                transition: all 0.5s ease;
            }
        }
        .text{
            p{
                margin-bottom: 0;
                font-size: 15px;
                font-weight: 500;
                color: var(--white);
                font-family: var(--font-exo);
            }
            a{
                font-size: 15px;
                font-weight: 500;
                color: var(--white);
                font-family: var(--font-exo);  
            }
            h4{
                color: var(--primary-color3);
            }
        }
    }
}
.contact-image-area{
    background: linear-gradient(rgba(41, 43, 49, 0.85),rgba(41, 43, 49, 0.85)),url(/public/images/bg/contact-bg.png);
    @include img-adjust();
    position: relative;
    min-height: 490px;
    @include sm-down-device(){
        display: none;
        visibility: hidden;
    }
    .contact-img{
        position: absolute;
        bottom: 0;
        right: 5%;
        max-width: 100%;
        height: auto;
        @include xxl-device(){
            right: 0%;
        }
        @include xl-device(){
            max-width: 70%;
            right: 0%;
        }
        @include lg-device(){
            max-width: 70%;
            right: 0%;
        }
        @include sm-device(){
            max-width: 60%;
            right: 0%;
        }
    }
}

/*=======================================
 18. Latest News Section
=======================================*/
.l-news-single{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    min-height: 400px;
    padding: 30px;
    display: flex;
    align-items: flex-end;
    background: linear-gradient(179.97deg, rgba(32, 32, 32, 0) 20.42%, #202020 99.97%);
    @include xl-device(){
        min-height: 400px;
    }
    @include lg-device(){
        min-height: 370px;
    }
    @include md-device(){
        min-height: 360px;
    }
    @include sm-device(){
        min-height: 400px;
    }
    @include sm-down-device(){
        min-height: 360px;
    }

    .casestudy1{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.65s ease-out 0s;
    }
    .text{
        .date{
            font-size: 15px;
            font-weight: 500;
            color: var(--primary-color1);
            font-family: var(--font-exo);
            margin-bottom: 10px;
        }
        h4{
            font-size: 1.375em;
            font-weight: 700;
            color: var(--white);
            margin-bottom: 0px;
            line-height: 1.4;
            position: relative;
            padding-top: 0px;
            transition: all 0.65s ease-out 0s;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 30px;
                width: 0px;
                opacity: 0;
                height: 1px;
                border-radius: 10px;
                background: var(--primary-color1);
                transition: all 0.65s ease-out 0s;
            }
            &:hover{
                &::after{
                    width: 100px;
                    opacity: 1;
                }
            }
        }
    }
    .news-badge{
        position: absolute;
        right: 30px;
        top: 30px;
        border-radius: 4px;
        transition: all 0.55s ease-out 0s;
        background: var(--primary-color1);
        padding: 3px 12px;
        &:hover{
            background: var(--dark-bg);
            color: var(--white);
        }
    }
    &:hover{
        .casestudy1{
            transform: scale(1.2) rotate(3deg);
        }
    }
}
.latest-articel-area{
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 25px;
}
.recent-news{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        margin-bottom: 30px;
        .image{
            border-radius: 5px;
            overflow: hidden;
            min-width: 90px;

            img{
               max-width: 100%;
            }
        }
        .text{
            h6{
                margin-bottom: 10px;
                a{
                    font-size: 15px;
                    font-weight: 600;
                    font-family: var(--font-lora);
                    color: var(--text-color1);
                    transition: all 0.35s ease-out 0s;
                    &:hover{
                        color: var(--primary-color2);
                    }
                }
            }
            .post-meta{
                @include flex(space-between);
                a, .date{
                    font-size: 11px;
                    font-weight: 500;
                    color: var(--primary-color2);
                    font-family: var(--font-exo);
                }
            }
        }
    }
}
.l-news-section{
    .swiper{
        padding: 12px;
        margin: -12px;
    }
}
.blog-single1{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    width: 100%;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    .image{
        position: relative;
        z-index: 1;
        img{
            width: 100%;
            border-radius: 10px 10px 0px 0px;
        }
        .blog-badge{
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 1rem;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--text-color1);
            padding: 5px 26px;
            cursor: pointer;
            background: var(--white);
            transition:  all 0.35s ease-out 0s;
            &:hover{
                background: var(--primary-color2);
                color: var(--white);
            }
        }
    }
    .post-meta{
        @include flex(space-between);
        margin-bottom: 10px;
        a, .date{
            font-size: 15px;
            font-weight: 500;
            color: var(--primary-color2);
            font-family: var(--font-exo);
        }
    }
    .text{
        padding: 30px 20px;
        h4{
            a{
                font-size: 22px;
                font-weight: 600;
                color: var(--text-color1);
                line-height: 1.4;
                transition: all 0.45s ease-out 0s;
            }
            &:hover{
                a{
                    color: var(--primary-color3);
                }
            }
        }
    }
    &.style-2{
        margin-bottom: 30px;
    }
}
.blog-single2{
    border-radius: 10px 0px 10px 10px;
    overflow: hidden;
    &:hover{
        .image{
            img{
                transform: scale(1.1);
            }
        }
    }
    .image{
        overflow: hidden;
        img{
            width: 100%;
            transition: all 0.65s ease;
        }
        position: relative;
        .blog-badge{
            position: absolute;
            top: 0;
            right: 0;
            @include font(16px,400,var(--dark-mode-bg));
            border-radius: 0px 0px 0px 10px;
            padding: 4px 20px;
            background: #D7B679;
            z-index: 9;
            transition: all 0.5s ease;
        }
    }
    .content{
        padding: 25px 20px;
        background: var(--dark3);
        .post-meta-list{
            @include list(none,0 0 13px 0,0);
            @include flex(flex-start);
            gap: 30px;
            li{
                @include font(13px,400, var(--primary-color3));
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -15px;
                    border-radius: 10px;
                    background: var(--white);
                    height: 7px;
                    width: 1px;
                }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
            }
        }
        h5{
            margin-bottom: 13px;
            a{
                @include font(1.25rem,700, var(--white));
                line-height: 1.6;
                transition: all 0.5s ease-out 0s;
                text-transform: capitalize;
            }
            &:hover{
                a{
                    color: var(--primary-color3);
                }
            }
        }
        p{
            @include font(16px,400,var(--dark-mode-light));
            margin-bottom: 0;
            line-height: 1.8;
        }
    }
    &.sibling2{
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        .content{
            background: var(--white);
            h5{
                a{
                    @include font(1.25rem,700, var(--dark3));
                }
                &:hover{
                    a{
                        color: var(--primary-color3);
                    }
                }
            }
            p{
                @include font(16px,400,var(--text-color2));
            }
        }  
    }
}
.blog-standard-single{
    position: relative;
    margin-bottom: 60px;
    .image{
        position: relative;
        z-index: 1;
        margin-bottom: 25px;
        img{
            width: 100%;
            border-radius: 5px;
        }
        .blog-badge{
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 1rem;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--text-color1);
            padding: 5px 26px;
            cursor: pointer;
            background: var(--white);
            transition:  all 0.35s ease-out 0s;
            &:hover{
                background: var(--primary-color2);
                color: var(--white);
            }
        }
    }
    .text{
        .post-meta-list{
            margin-top: 20px;
            list-style: none;
            padding: 0;
            @include flex(flex-start);
            flex-wrap: wrap;
            gap: 8%;
            margin-bottom: 20px;
            @include sm-down-device(){
                gap: 18px;
            }
            li{
                color: var(--primary-color2);
                font-size: 1rem;
                font-weight: 500;
                font-family: var(--font-exo);
                img{
                    margin-right: 5px;
                    margin-top: -3px;
                }
            }
        }
        h2{
            a{
                font-size: 2.2rem;
                font-weight: 700;
                color: var(--text-color1);
                line-height: 1.35;
            }
        }
        .read-more-btn{
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--primary-color2);
            font-family: var(--font-lora);
            transition: all 0.5s ease-out 0s;
            margin-top: 10px;
            display: inline-block;
            .bi{
                font-size: 1.25rem;
                vertical-align: middle;
                margin-left: 10px;
            }
            &:hover{
                letter-spacing: 1px;
                color: var(--text-color1);
            }
        }
    }
}
.blog-widget-item{
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    margin-bottom: 40px;
    &:last-child{
        margin-bottom: 0px;
    }
    .blog-widget-title{
        font-size: 1.375rem;
        font-weight: 700;
        color: var(--text-color1);
        padding: 20px 20px;
        border-bottom: 1px solid #F0F0F0;
        @include lg-down-device(){
            text-align: center;
        }
    }
    .search-area{
        padding: 25px 20px;
        form{
            .search-with-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                input{
                    height: 50px;
                    border-radius: 3px 0px 0px 3px;
                    width: 100%;
                    border: 1px solid #F0F0F0;
                    padding: 20px;
                    background:  #F0F0F0;
                    &::placeholder{
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--text-color2);
                    }
                }
                button{
                    border-radius: 0px 5px 5px 0px;
                    height: 50px;
                    min-width: 60px;
                    background: var(--primary-color1);
                    border: none;
                    transition: all 0.42s ease-in-out;
                    i{
                        color: var(--white);
                    }
                    &:hover{
                        background: var(--text-color2);
                    }
                }
            }
        }
    }
    .blog-category{
        .category-list{
            margin: 0;
            list-style: none;
            padding: 18px 20px;
            li{
                margin-bottom: 17px;
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    @include flex(space-between);
                    &:hover{
                        span{
                            color: var(--primary-color2);
                        }
                    }
                }
                span{
                    font-size: 1rem;
                    font-weight: 600;
                    color: var(--text-color1);
                    font-family: var(--font-exo);
                    transition: all 0.5s ease-out 0s;
                }
            }
        }
    }
    .recent-blog{
        .recent-post-list{
            list-style: none;
            padding: 25px 20px;
            margin: 0;
            li{
                margin-bottom: 25px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
        .recent-post-item{
            @include flex(flex-start);
            gap: 15px;
            .recent-post-content{
                span{
                    font-size: 1rem;
                    font-weight: 500;
                    color: #5F6062;
                    font-family: var(--font-exo);
                    margin-bottom: 5px;
                    display: inline-block;
                }
                h6{
                    a{
                        font-size: 1.125rem;
                        font-weight: 600;
                        color: var(--text-color1);
                        line-height: 1.3;
                        transition: all 0.35s ease-out 0s;
                        &:hover{
                            color: var(--primary-color2);
                        }
                    }
                }
            }
        }
    }
    .comment-slider-area{
        position: relative;
    }
    .comnt-slider{
        width: 100%;
        .comment-slide-single{
            padding: 20px 20px;
            min-width: 100%;
            margin-left: auto;
            margin-right: auto;
            h5{
                font-size: 1.25rem;
                font-weight: 700;
                color: var(--text-color1);
            }
            span{
                font-size: 15px;
                font-weight: 500;
                color: var(--primary-color2);
                font-family: var(--font-exo);
            }
        }
        .slider-arrows{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }
        .slider-arrows .swiper-prev-arrow {
            width: 35px;
            height: 30px;
            line-height: 31px;
            text-align: center;
            border-radius: 0; 
            background: #F0F0F0;
            border: none;
            &:hover{
                transition: all 0.3s;
                background: var(--primary-color1);
                color:#fff;
            }
        }
        .slider-arrows .swiper-next-arrow {
            width: 35px;
            height: 30px;
            line-height: 31px;
            text-align: center;
            border-radius: 0; 
            background: #F0F0F0;
            border: none;
            &:hover{
                transition: all 0.3s;
                background: var(--primary-color1);
                color:#fff;
            }
        }
    }
}
.tag-list{
    list-style: none;
    padding: 25px 20px;
    margin: 0;
    li{
        a{
            font-size: 13px;
            font-weight: 600;
            font-family: var(--font-exo);
            border: 1px solid #F0F0F0;
            padding: 7px 25px;
            border-radius: 3px;
            color: #A0A0A0;
            display: inline-flex;
            transition: all 0.5s ease-out 0s;
            &:hover{
                background: var(--primary-color2);
                border: 1px solid var(--primary-color2);
                color: var(--white);
            }
        }
    }
}
.blog-details-single{
    position: relative;
    margin-bottom: 50px;
    .image{
        position: relative;
        z-index: 1;
        margin-bottom: 25px;
        img{
            width: 100%;
            border-radius: 5px;
        }
        .blog-badge{
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 1rem;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--text-color1);
            padding: 5px 26px;
            cursor: pointer;
            background: var(--white);
            transition:  all 0.35s ease-out 0s;
            &:hover{
                background: var(--primary-color2);
                color: var(--white);
            }
        }
    }
    .text{
        .post-meta-list{
            margin-top: 20px;
            list-style: none;
            padding: 0;
            @include flex(flex-start);
            flex-wrap: wrap;
            gap: 8%;
            margin-bottom: 20px;
            @include sm-down-device(){
                gap: 18px;
            }
              
            li{
                color: var(--primary-color2);
                font-size: 1rem;
                font-weight: 500;
                font-family: var(--font-exo);
                img{
                    margin-right: 5px;
                    margin-top: -3px;
                }
            }
        }
        h2{
            a{
                font-size: 2.5rem;
                font-weight: 700;
                color: var(--text-color1);
                line-height: 1.4;
                @include md-down-device(){
                    font-size: 2.2rem;
                }
            }
        }
    }
    .blockquote-area{
        position: relative;
        padding: 10px 25px;
        margin: 50px 0px 50px 0;
        .bquote1{
            position: absolute;
            top: 0;
            left: 0;
        }
        .bquote2{
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    blockquote{
        font-size: 22px;
        font-weight: 500;
        font-family: var(--font-exo);
        color: #333333;
        margin-bottom: 0;
        line-height: 1.7;
    }
}
.blog-sharea-area{
    margin-bottom: 60px;
}
.blog-share{
    overflow: hidden;
    height: 24px;
    cursor: pointer;
    text-align: right;
    &:hover{
        .front{
            transform: translateY(-24px);
        }
        .back{
            transform: translateY(-24px);
        }    
    }

    .front{
        text-align: right;
        transition: all 0.62s ease-in-out;
        img{
            vertical-align:baseline;
        }
        span{
            font-size: 17px;
            font-weight: 600;
            color: var(--text-color);
            display: inline-block;
            margin-bottom: 0px;
            margin-left: 5px;
            font-family: var(--font-lora);
        }
    }
    .back{
        text-align: end;
        transition: all 0.62s ease-in-out;
        .share-list{
            margin: 0;
            padding: 0;
            list-style: none;
            a{
                i{
                    color: var(--text-color2);
                    transition: all 0.35s ease-in-out;
                }
                &:hover{
                    i{
                        color: var(--primary-color2);
                    }
                }
            }
        }
    }
}
.another-post-area{
    background-color: #F9F6F6;
    padding: 45px 30px 15px 30px;
    .prev-post{
        cursor: pointer;
        margin-bottom: 20px;
        span{
            @include font(12px, 500, var(--text-color1));
            color: var(--text-color1);
            transition: all 0.5s ease-out 0s;
            display: inline-block;
            margin-bottom: 5px;
            svg{
                fill:  var(--text-color1);
                margin-right: 6px;
                margin-top: -1px;
                transition: all 0.5s ease-out 0s;
            }
        }
        h5{
            font-size: 1.125rem;
            font-weight: 600;
            color: var(--text-color1);
        }
        &:hover{
            span{
                color: var(--primary-color2);
                svg{
                    fill:  var(--primary-color2);
                }
            }
        }
    }
    .next-post{
        @extend .prev-post; 
        svg{
            margin-left: 6px;
        }
    }
}
h3.comment-title{
    @include font(1.875rem,700, var(--text-color1));
    margin-bottom: 26px;
}
.comment-area{
    .comment-list{
        @include list(none,0,0);
    }
    .single-comment{
        @include flex(start);
        gap: 20px;
        align-items: start;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        padding: 35px 20px 25px 20px;
        @include sm-down-device(){
            flex-wrap: wrap;
            padding: 30px 15px 20px 15px;
        }
        .image{
            img{
                border-radius: 5px;
                min-width: 70px;
            }
        }
        .content {
            max-width: 725px;
            width: 100%;
        }
        .comment-author{
            width: 100%;
            margin-bottom: 15px;
            @include flex(space-between);
            align-items: center;
            .designation{
               h5{
                   @include font(1.25rem,700, var(--text-color1));
               }
               span{
                @include font(0.938rem, 500, #a4a4a4);
               }
            }
            .reply-btn{
                @include font(1rem, 500, var(--primary-color2));
                background: transparent;
                outline: none;
                border: 1px solid var(--primary-color2);
                border-radius: 3px;
                padding: 3px 20px;
                transition: all 0.5s ease-out 0s;
                @include sm-down-device(){
                    padding: 3px 14px;
                }
                .bi{
                    margin-right: 5px;
                    transition: all 0.5s ease-out 0s;
                }
                &:hover{
                    color: var(--white);
                    background: var(--primary-color2);
                    .bi{
                        color: var(--white);
                    }
                }
            }
        }
    }

}
.comment-form{
    .form-inner{
        margin-bottom: 25px;
        input{
            width: 100%;
            border: 1px solid rgba(41, 43, 49, 0.05);
            border-radius: 5px;
            padding: 15px 25px;
            transition: all 0.5s ease-in-out;
            font-size: 15px;
            font-family: var(--font-exo);
            &::placeholder{
                font-size: 12px;
                font-weight: 500;
                color: var(--text-light);
            }
            &:focus{
                border: 1px solid var(--primary-color2);
            }
        }
        textarea{
            @extend input;
        }
    }
}
.cmment-submit-btn{
    margin-top: 51px;
    display: inline-flex;
    @include lg-down-device(){
        margin-top: 25px;
    }
}
/*=======================================
 19. Lawyer Profile Section
=======================================*/
.lawyer-profile{
    padding-left: 25px;
    @include lg-down-device(){
        padding-left: 0;
    }
    h2{
        @include font(2.813rem, 700, var(--text-color1));
    }
    > span{
        @include font(18px,500, var(--primary-color2));
        margin-bottom: 15px;
        display: inline-block;
    }
}
.lawyer-counter{
    padding: 15px 0px 35px 0px;
}
.lawyer-counter-single{
    text-align: center;
    .coundown{
        h3.odometer{
            @include font(2.188rem, 700, var(--text-color1));
            margin-bottom: 0px;
        }
        h5{
            @include font(1.125rem, 500, rgba(#202020,0.5));
        }
    }
}
.lawyer-short-details{
    h4{
        @include font(1.5rem,500,var(--primary-color2));
        position: relative;
        display: inline-block;
        margin-bottom: 30px;
        &::after{
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            height: 1px;
            width: 100%;
            background: var(--primary-color2);
        }
    }
}
.details-list{
    @include list(none,0,0);
    width: 100%;
    @include flex(flex-start);
    flex-wrap: wrap;
    flex-direction: row;
    > li{
        width: 50%;
        margin-bottom: 20px;
        // padding-right: 30px;
       @include sm-down-device(){
        width: 100%;
       }
        
        span{
            @include font(1.125rem,500,#8A8A8A);
        }
        h5{
            @include font(1.25rem,600,var(--text-color1));
        }
    }
}
.lawyer-social{
    @include list(none,0,0);
    @include flex(flex-start);
    @include xl-down-device(){
        @include flex(center);
    }
    gap: 20px;
    li{
        height: 24px;
        width: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 5px;
        background: rgba(#202020,0.2);
        transition: all 0.5s ease-out 0s;
        i{
            font-size: 15px;
        }
        &:hover{
            background: var(--primary-color2);
        }
    }
}
.lawyer-edu-title{
    @include font(2rem,700,var(--text-color1));
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 25px;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, #005153 0%, rgba(0, 81, 83, 0.786458) 21.35%, rgba(0, 81, 83, 0.453125) 54.69%, rgba(0, 81, 83, 0.151042) 84.9%, rgba(0, 81, 83, 0) 100%);
        border-radius: 23px;

    }
}
.lawyer-edu-list{
    @include list(none,0px,0 0 0 45px);
    > li{
        @include font(1rem, 400, var(--text-color2));
        font-family: var(--font-exo);
        line-height: 1.8;
        margin-bottom: 25px;
        position: relative;
        text-align: left;
        &:last-child{
            margin-bottom: 0px;
        }
        &::before{
            content: url('/public/images/icons/graduation-icon.svg');
            position: absolute;
            left: -50px;
            top: 10px;
        }
        span{
            @include font(1.125rem, 600, var(--text-color2));
        }
    }
}
.lawyer-details-section{
    .lawyer-info{
        margin-bottom: 60px;
    }
    .nav-pills{
        button.nav-link{
            background: var(--dark);
            width: 100%;
            max-width: 280px;
            transition: all 0.5s ease-out 0s;
            @include font(1.375rem,700, #fff);
            font-family: var(--font-lora);
            padding: 12px 20px;
            margin-bottom: 20px;
            border-radius: 5px;
            @include md-device(){
                max-width: unset;
                width: unset;
                margin-bottom: 0;
                padding: 9px 20px;
                @include font(1.375rem,500, #fff);
            }
            @include sm-device(){
                max-width: unset;
                width: unset;
                margin-bottom: 0;
                padding: 9px 20px;
                @include font(1.375rem,500, #fff);
            }
            @include sm-down-device(){
                margin-bottom: 0;
            }
            &:hover{
                background: var(--primary-color2);
            }
            &.active{
                background: var(--primary-color2);
            }
        }
    }
}
/*=======================================
 20. Error-page
=======================================*/

/*=======================================
 21. Footer-section
=======================================*/
.footer-section{
    background-image: linear-gradient(rgba( #292B31,0.6),rgba( #292B31,0.6)),url('/public/images/bg/footer1-bg.webp');
    @include img-adjust();
    position: relative;
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background:  #292B31;
        z-index: -2;
    }
    .footer-about{
        img{
            margin-bottom: 30px;
        }
        p{
            font-size: 15px;
            font-weight: 400;
            font-family: var(--font-exo);
            color: var(--white);
            line-height: 1.6;
            margin-bottom: 30px;
        }
        .footer-social{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            i{
                width: 35px;
                height: 35px;
                line-height: 35px;
                color: var(--white);
                border-radius: 10px;
                background: #0F1012;
                text-align: center;
                font-size: 18px;
                transition: all 0.5s ease-out 0s;
                &:hover{
                    background: var(--primary-color1);
                }
            }
        }
        .open-hour{
            margin-top: 25px;
            h6{
                font-size: 18px;
                font-weight: 500;
                color: var(--primary-color1);
            }
            p{
                margin-bottom: 0px;
            }
        }
    }
    .footer-top{
        padding: 90px 0px;
    }
    .footer-item{
        h4{
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--primary-color1);
            margin-bottom: 20px;
        }
        .link-list{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin-bottom: 15px;
                position: relative;
                padding-left: 0px;
                transition: all 0.55s ease;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 10px;
                    height: 1px;
                    border-radius: 5px;
                    background-color: var(--primary-color1);
                    opacity: 0;
                    transition: all 0.55s ease;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                &:hover{
                    padding-left: 18px;
                    &::before{
                        opacity: 1;
                    }
                }
            }
            a{
                font-size: 16px;
                font-weight: 500;
                font-family: var(--font-exo);
                color: var(--white);
            }
        }
        .contact-list{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 30px;
                gap: 12px;
                &:last-child{
                    margin-bottom: 0px;
                }
                .icon{
                    svg{
                        fill: var(--primary-color1);
                    }
                }
                .text{
                    color: var(--white);
                    a{
                        display: block;
                        margin-bottom: 8px;
                    }
                }
            }
        }
        .recent-caselist{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0px;
                }
                .image{
                    border-radius: 5px;
                    overflow: hidden;
                }
                .text{
                    span{
                        font-size: 12px;
                        font-weight: 500;
                        font-family: var(--font-exo);
                        color: var(--primary-color1);
                        display: inline-block;
                        position: relative;
                        padding-bottom: 3px;
                        margin-bottom: 8px;
                        &::after{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 1px;
                            border-radius: 10px;
                            background: var(--primary-color1);
                        }
                    }
                    h5{
                        a{
                            font-size: 20px;
                            font-weight: 500;
                            font-family: var(--font-lora);
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }
    .footer-bottom{
        padding: 16px 0px;
        border-top: 1px solid rgba(#fff,0.1);
    
        p{
            font-size: 13px;
            font-weight: 400;
            font-family: var(--font-exo);
            color: var(--white);
            margin-bottom: 0;
            margin-right: 30px;
            @include lg-down-device(){
                margin-right: 0px;
            }
    
            .egns-lab{
                font-size: 13px;
                font-weight: 600;
                color: var(--primary-color1);
            }
        }
    
        .footer-logo-list{
            margin: 0;
            padding: 0;
            list-style: none;
            display: inline-flex;
            
            li{
                margin-right: 20px;
                img{
                    transition: all 0.6s ease;
                }
                &:last-child{
                    margin-right: 0px;
                }
    
                &:hover{
                    img{
                        transform: rotate(360deg);
                    }
                }
            }
        }
        .f-bottom-list{
            margin: 0;
            padding: 0;
            li{
                &:first-child{
                    a{
                        position: relative;
                        padding-right: 15px;
                        margin-right: 15px;
                        &::after{
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 2px;
                            height: 15px;
                            background: var(--white);
                        }
                    }
                }
            }
            a{
                font-size: 14px;
                font-weight: 500;
                color: var(--white);
                transition: all 0.42s ease;
                &:hover{
                    color: var(--primary-color1);
                }
            }
        }
    }
    &.sibling2{
        h4{
          color:var(--primary-color2);
        } 
        .footer-about{
            .footer-social{
                i{
                    &:hover{
                        background: var(--primary-color2);
                    }
                }
            }
            .open-hour{
                h6{
                    color: var(--primary-color2);
                }
            }
        }
        .link-list{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                &::before{
                    background-color: var(--primary-color2);
                }
            }
        }
        .recent-caselist{
          list-style: none;
          margin: 0;
          padding: 0;
          li{
              .text{
                  span{
                      color: var(--primary-color2);
                      &::after{
                          content: '';
                          background: var(--primary-color2);
                      }
                  }
              }
          }
      } 
      .contact-list{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            .icon{
                svg{
                    fill: var(--primary-color2);
                }
            }
        }
    }
    .f-bottom-list{
        a{
            &:hover{
                color: var(--primary-color2);
            }
        }
    }
  }
  &.sibling3{
    h4{
      color:var(--primary-color3);
    } 
    .footer-about{
        .footer-social{
            i{
                &:hover{
                    background: var(--primary-color3);
                }
            }
        }
        .open-hour{
            h6{
                color: var(--primary-color3);
            }
        }
    }
    .link-list{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            &::before{
                background-color: var(--primary-color3);
            }
        }
    }
    .recent-caselist{
      list-style: none;
      margin: 0;
      padding: 0;
      li{
          .text{
              span{
                  color: var(--primary-color3);
                  &::after{
                      content: '';
                      background: var(--primary-color3);
                  }
              }
          }
      }
  } 
  .contact-list{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
        .icon{
            svg{
                fill: var(--primary-color3);
            }
        }
    }
}
.f-bottom-list{
    a{
        &:hover{
            color: var(--primary-color3);
        }
    }
}
.footer-bottom{

    p{
        .egns-lab{
            font-size: 13px;
            font-weight: 600;
            color: var(--primary-color3);
        }
    }
    .f-bottom-list{
        margin: 0;
        padding: 0;
        a{
            font-size: 14px;
            font-weight: 500;
            color: var(--white);
            transition: all 0.42s ease;
            &:hover{
                color: var(--primary-color3);
            }
        }
    }
}
}
}
.progress-area{
    @include md-device(){
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
        justify-content: flex-start;
        margin-top: 30px;
    }
}
.progress-area2{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // gap: 25px;
        justify-content: flex-start;
        margin-top: 0px;
        @include md-down-device(){
            flex-wrap: wrap;
        }
}
.about2-counter-area{
    margin-top: 15px;
}
#progress{
    svg {
        height: 150px;
        margin: auto;
        display: block;
      }
      
      path {
        stroke-linecap: round;
        stroke-width: 6;
      }
      
      path.grey {
        stroke: var(--dark-bg);
      }
      
      path.blue {
        stroke: var(--primary-color1);
        stroke-dasharray: 198;
        stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
      }
      text {
        font-size: 30px;
        font-weight: 700;
        font-family: 'Exo';
    }
}
#progress1{
    svg {
        height: 150px;
        margin: auto;
        display: block;
      }
      
      path {
        stroke-linecap: round;
        stroke-width: 6;
      }
      
      path.grey {
        stroke: var(--dark-bg);
      }
      
      path.blue {
        stroke: var(--primary-color1);
        stroke-dasharray: 198;
        stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
      }
      text {
        font-size: 30px;
        font-weight: 700;
        font-family: 'Exo';
    }
}
#progress2{
    svg {
        height: 150px;
        margin: auto;
        display: block;
      }
      
      path {
        stroke-linecap: round;
        stroke-width: 6;
      }
      
      path.grey {
        stroke: var(--green);
      }
      
      path.blue {
        stroke: var(--primary-color1);
        stroke-dasharray: 198;
        stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
      }
      text {
        font-size: 30px;
        font-weight: 700;
        font-family: 'Exo';
    }
}
#progress3{
    svg {
        height: 150px;
        margin: auto;
        display: block;
      }
      
      path {
        stroke-linecap: round;
        stroke-width: 6;
      }
      
      path.grey {
        stroke: var(--green);
      }
      
      path.blue {
        stroke: var(--primary-color1);
        stroke-dasharray: 198;
        stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
      }
      text {
        font-size: 30px;
        font-weight: 700;
        font-family: 'Exo';
    }
}
#progress4{
    svg {
        height: 150px;
        margin: auto;
        display: block;
      }
      
      path {
        stroke-linecap: round;
        stroke-width: 6;
      }
      
      path.grey {
        stroke: var(--green);
      }
      
      path.blue {
        stroke: var(--primary-color1);
        stroke-dasharray: 198;
        stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
      }
      text {
        font-size: 30px;
        font-weight: 700;
        font-family: 'Exo';
    }
}

.progress-item{
    background: var(--white);
    border-radius: 10px;
    padding: 5px 0px 15px 0px;
    width: 100%;
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    @include md-device(){
        margin-left: 0;
        margin-right: 0;
    }
    &:after{
        content: url('/public/images/icons/winner-badge.svg');
        position: absolute;
        left: 50%;
        top: 65%;
        transform: translate(-50%,-50%);
        z-index: 9;
    }
    h5{
        @include font(1.25rem,500,var(--text-color1));
        margin-top: 5px;
    }
}

/*=======================================
22.Testimonial Seciton
=======================================*/

.testimonial-section{
    position: relative;
    .section-bg1{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        @include md-down-device(){
            display: none;
            visibility: hidden;
        }
    }  
    .pagination-wrap{
        position: relative;
        z-index: -99;
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background: var(--dark);
            width: 100%;
            max-width: 470px;
            min-width: 470px;
            height: 480px;
            border-radius: 20px;
            z-index: -9;
        }
    }
    .swiper{
        padding: 15px;
        margin: -15px;
    }
}
.testi1-slider{
    padding-top: 60px;
    position: relative;
    z-index: 90;
}
.testi1-single{
    // background: radial-gradient(36.96% 327.68% at 48.59% 48.09%, rgba(255, 255, 255, 0) 60%, rgba(202, 148, 87, 0.05) 100%);
    background: var(--primary-light2);
    border-radius: 10px;
    position: relative;
    padding: 0px 30px 30px;
    width: 100%;
    z-index: 9;
    .testi-author{
        border-radius: 50%;
        max-width: 120px;
        width: 100%;
        height: auto;
        margin-top: -60px;
        margin-bottom: 25px;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        font-family: var(--font-exo);
        line-height: 1.8;
    }
    .bottom{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
    }
    .author{
        margin-top: 15px;
        h4{
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--text-color);
        }
        span{
            font-size: 16px;
            font-weight: 500;
            color: var(--primary-color1);
            display: inline-block;
        }
    }
}
.testi5-arrows{
    position: absolute;
    // bottom: 200px;
    bottom: 0px;
    z-index: 9;
    left: -120px;
    @include xl-device(){
        bottom: 40px;
    }
    @include lg-device(){
        bottom: 30px;
        left: -50px;
    }}
.testi3-arrows {
    position: absolute;
    bottom: 80px;
    z-index: 9;
    left: -142px;
    @include xl-device(){
        bottom: 40px;
    }
    @include lg-device(){
        bottom: 30px;
        left: -50px;
    }
}
.testi3-single{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @include md-down-device(){
        flex-direction: column;
    }
    .image{
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        @include md-down-device(){
            border-radius: 10px 10px 0px 0px;
        }
        img{
            width: 100%;
        }
        .img-content{
            height: 100%;
            width: 100%;
            padding: 40px 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(174.31deg, rgba(27, 27, 34, 0) 7.5%, rgba(27, 27, 34, 0.9) 77.14%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            h3{
                @include font(2.188rem,700,#fff);
                margin-bottom: 5px;
                @include xxl-down-device(){
                    @include font(1.8rem,700,#fff);
                }
            }
            span{
                @include font(1.125rem,500,var(--primary-color3));
                display: inline-block;
                margin-bottom: 0;
            }
        }
    }
    .content{
        position: relative;
        width: 100%;
        max-width: 920px;
        background: var(--dark3);
        padding: 50px 65px;
        margin-left: -80px;
        border-radius: 10px;
        @include xl-device(){
            margin-left: -80px;
            padding: 50px 55px;
        }
        @include xl-down-device(){
            margin-left: 0;
        }
        @include lg-device(){
            margin-left: -40px;
            padding: 50px 45px;
        }
        @include md-down-device(){
            padding: 50px 25px;
        }
        p{
            margin-bottom: 0px;
            @include font(1.25rem,400,#c2c2c2);
            line-height: 1.9;
        }
        .testi3-quote{
            position: absolute;
            left: 15px;
            top: 15px;
            opacity: 1;
            @include sm-down-device(){
                width: 35px;
            }
        }
        span{
            @include font(3.5rem,700,rgba(#D7B679,0.13));
            font-family: var(--font-lora);
            // margin-left: auto;
            display: inline-block;
            width: 100%;
            text-align: right;
            line-height: 1;

        }
    }
    &.sibling2{
        .content{
            background-color: var(--white);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            p{
                color: #333333;
            }
        }
    }
}

.slider-arrows{
    .swiper-prev-arrow{
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid var(--primary-color1);
        border-radius: 50%;
        img{
            transition: all 0.42s ease;
            margin-left: 20px;
        }
        &:hover{
            img{
                margin-left: 0px;
            }
        }
        &.style-2{
            width: 30px;
            height: 30px;
            line-height: 30px;
            img{
                transform: rotate(90deg);
                margin-top: 18px !important;
                margin-left: unset;
            }
            &:hover{
                img{
                    margin-left: unset;
                    margin-top: 0px !important;
                }
            }
        }
        &.style-3{
            border: 1px solid var(--primary-color3);
        }
    }
    .swiper-next-arrow{
       width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid var(--primary-color1);
        border-radius: 50%;
        img{
            transition: all 0.42s ease;
            margin-left: -30px;
        }
        &:hover{
            img{
                margin-left: 0px;
            }
        }
        &.style-2{
            width: 30px;
            height: 30px;
            line-height: 30px;
            img{
                transform: rotate(90deg);
                margin-bottom: 18px !important;
                margin-left: unset;
            }
            &:hover{
                img{
                    margin-left: unset;
                    margin-bottom: 0px !important;
                }
            }
        }
        &.style-3{
            border: 1px solid var(--primary-color3);
        }
    }
}
.swiper-pagination-total{
    display: none;
}
.swiper-pagination {
    height: 15px;
    position: absolute;
    // bottom: 30px !important;
}

/*=======================================
 23.  Breadcrumb-section
=======================================*/
.breadcrumb-section{
    background: linear-gradient(275.93deg, rgba(32, 32, 32, 0.8) 25.55%, rgba(32, 32, 32, 0.8) 95.49%), url('/public/images/bg/breadcrumb-bg.webp');
    @include img-adjust();
    padding: 12.5rem 0px;
}
.breadcrumb-title{
    font-size: 3.125rem;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 15px;
}
.breadcrumb{
    margin: 0;
    padding: 0;
    justify-content: center;
    .breadcrumb-item{
        color: var(--primary-color3);
        font-size: 20px;
        font-weight: 500;
        font-family: var(--font-exo);
        position: relative;
        padding-left: 25px;
        &:first-child{
            padding-left: 0px;
            padding-right: 10px;
        }
        &.active{
            color: #DDDDDD;
        }
    }
}
.breadcrumb-item+.breadcrumb-item::before {
    content: unset;
}
.breadcrumb-item+.breadcrumb-item::after {
    content: url('/public/images/icons/dotted-arrow.svg');
    position: absolute;
    top: -2px;
    left: 0;
}

/*=======================================
 24. Service details
=======================================*/
.service-details-text{
    h2{
        font-size: 2.813rem;
        font-weight: 700;
        color: var(--text-color1);
        margin-bottom: 25px;
        line-height: 1.2;
    }
}
.service-img-grp{
    padding: 50px 0 60px 0px;
    img{
        @include lg-down-device(){
            margin-bottom: 30px;
        }
    }
}
.service-list{
    list-style: none;
    background: var(--green);
    margin: 0 0 40px 0;
    padding: 30px 0px;
    li{
        @include flex(space-between);
        padding: 10px 40px;
        transition: all 0.45s ease-out 0s;
        &:last-child{
            margin-bottom: 0px;
        }
        a{
            font-size: 18px;
            font-weight: 500;
            font-family: var(--font-exo);
            color: var(--white);
            transition: all 0.52s ease;
        }
        &:hover{
            background: var(--primary-color3);
            >a{
                padding-left: 10px;
            }
        }
    }
}
.feature-title{
    font-size: 2.188rem;
    font-weight: 700;
    color: var(--primary-color3);
    border-bottom: 2px solid var(--primary-color3);
    display: inline-block;
    padding-bottom: 0px;
    margin-bottom: 45px;
}
.feature-list{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
        margin-bottom: 35px;
        &:last-child{
            margin-bottom: 0px;
        }
        img{
            float: left;
            margin-right: 20px;
        }
        .text{
            h4{
                font-size: 1.5rem;
                font-weight: 700;
                color: var(--text-color1);
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 0px;
            }
        }
    }
}
.team-title{
    font-size: 2.188rem;
    font-weight: 700;
    color: var(--text-color1);
    margin-bottom: 30px;
}
/*=======================================
 25. Faq section
=======================================*/
.faq-section{
    .nav-pills{
        list-style: none;
        background: var(--green);
        margin: 0 0 40px 0;
        padding: 20px;
        text-align: center;
        border-radius: 5px;
        button.nav-link{
            padding: 8px 0px;
            transition: all 0.45s ease-out 0s;
            border-radius: 5px;
            font-size: 1.25rem;
            font-weight: 500;
            color: var(--white);
            margin-bottom: 0px;
            width: 100%;
            &.active{
                background: var(--primary-color2);
            }
            &:last-child{
                margin-bottom: 0px;
            }
            &:hover{
                background: var(--primary-color2);
            }
        }
    } 
}
.faq-form{
    background: var(--text-color1);
    padding: 30px 20px;
    border-radius: 5px;
    .form-inner{
        margin-bottom: 25px;
        input{
            width: 100%;
            border: 1px solid rgba(41, 43, 49, 0.05);
            border-radius: 5px;
            padding: 10px 20px;
            transition: all 0.5s ease-in-out;
            font-size: 15px;
            font-family: var(--font-exo);
            &::placeholder{
                font-size: 14px;
                font-weight: 500;
                color: var(--text-light);
            }
            &:focus{
                border: 1px solid var(--primary-color2);
            }
        }
        textarea{
            @extend input;
        }
    }
    .faq-form-title{
        display: inline-block;
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--white);
        font-family: var(--font-exo);
        margin-bottom: 25px;
    }
}
.faq-wrap {
    .faq-item {
      margin-bottom: 6px;
      border: none;
      &:last-child {
        margin-bottom: 0;
      }
      &.hover-btn{
          padding: 1px 1px 1px 1px;
      }
    }
  
    .accordion-button {
      font-family:var(--font-lora);
      font-weight: 600;
      font-size: 18px;
      background: var(--white);
      border-radius: 0px;
      color: var(--text-color1);
      cursor: pointer;
      transition: 0.45s ease-in-out;
      padding: 25px 0px;
      padding-right: 35px;
      margin-bottom: 0px;
      line-height: 1.6;
      border-bottom: 1px solid #eee;
  
      &:focus {
        z-index: unset;
        border-color: unset;
        outline: 0;
      }
  
      &::after {
        flex-shrink: 0;
        width: unset;
        height: unset;
        margin-left: auto;
        background-image: none;
        background-repeat: unset;
        background-size: unset;
        font-family: bootstrap-icons !important;
        position: absolute;
        right: 0;
        content: "\f4fe";
        transition: unset;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      &:not(.collapsed)::after {
        background-image: none;
        transform: unset;
        font-family: bootstrap-icons !important;
        content: "\f2ea";
        // color: var(--primary-color4);
        color: var(--primary-color2);

      }
      &:hover{
        background: var(--primary-color4);
        color: var(--primary-color2);
        &::after{
            color: var(--primary-color2);
        }
      }
    }
  
    .faq-body {
      @extend .para;
      font-family: var(--font-exo);
      background: var(--white);
      border-top: none;
      padding: 15px;
      text-align: left;
  
    }
    .accordion-button:not(.collapsed) { 
      background: var(--primary-color4);
      color: var(--primary-color2);
      font-weight: 600;
    }
  }
  .faq-title{
    margin-bottom: 20px;
    h2{
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--text-color1);
        margin-bottom: 12px;
    }
  }

/*=======================================
 26. History section
=======================================*/
.padding-x{
    padding-left: 3%;
    padding-right: 3%;
}
  .history-card{
    text-align: center;
    width: 100%;
    max-width: 420px;
    min-width: 280px;
    margin: -18px auto 0 auto;
    padding: 0px 30px;
    @include xl-down-device(){
        margin-top: 0px;
        margin-bottom: 30px;
    }
    &:hover{
        span{
            transform: translateY(20px);
        }
    }
    span{
        @include font(1.25rem,600,var(--text-color1));
        background-color: var(--primary-color3);
        border-radius: 5px;
        padding: 3px 30px;
        text-align: center;
        position: relative;
        display: inline-block;
        z-index: 1;
        margin-bottom: 40px;
        transition:  all 0.55s ease;
        @include xl-down-device(){
            margin-bottom: 25px;
        }
        &::before{
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            bottom: -3px;
            width: 25px;
            height: 25px;
            background-color: var(--primary-color3);
            z-index: -1;
        }
    }
    h3{
        @include font(1.875rem,600, var(--white));
        margin-bottom: 20px;
        line-height: 1.3;
        @include md-device(){
            font-size: 1.775rem;
            line-height: 1.4;
        }
    }
    p{
        @include font(16px, 400, var(--dark-mode-light));
        margin-bottom: 0;
    }
  }
  .map-section{
    iframe{
        width: 100%;
        display: block;
    }
  }